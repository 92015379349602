import { useEffect, useState } from "react";
import IndustryExpertise from "../components/IndustryExpertise";
import WhyEleven36 from "../components/WhyEleven36";
import { FaArrowCircleRight, FaArrowUp, FaRegStar } from "react-icons/fa";
import Drive from "../assets/images/youdriveus.mp4";
import { IoMoveOutline } from "react-icons/io5";
import { BsFlag } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi2";
import VidBg from "../assets/images/AdobeStock_53418187.mov";
// import HeroImg from "../assets/images/GroupPattern.svg";
import HeroImg from "../assets/images/abouthero.png";
import { Link } from "react-router-dom";
const AboutUs = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="z-50">
      <div className="relative pt-[100px] pb-[100px] md:pb-[100px] h-[100vh] overflow-hidden">
        {/* <img
          src={HeroImg}
          className="absolute top-0 left-[80%] bottom-0 w-full h-full object-cover z-30 container"
          alt=""
        /> */}
        <img
          src={HeroImg}
          className="absolute top-0 left-[40%] bottom-0 w-full h-full object-contain z-30"
          alt=""
        />
        {/* <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-black to-[#45C4B0] opacity-40 z-10"></div> */}
        <div className="absolute inset-0 w-full top-0 left-0 right-0 bottom-0 h-full radial-gradient-bg3 bg-opacity-5 z-10"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-20"></div>
        <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[3rem] lg:px-[3rem] mb-[60px]"></div>
        <div className="relative z-30 max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem]">
          <div>
            <div className="w-full md:w-[60%] mb-8">
              <h1 className="text-[28px] md:text-[44px] pb-3 font-NeueMedium text-white">
                Who we are
              </h1>
              <h1 className="text-[16px] md:text-[22px] text-[#A9A9A9] pb-3 font-NeueRegular">
                At Eleven36, we're a diverse team of strategic disruptors with
                the core belief that technology should be adapted to suit every
                aspect, contour, and phase of individual and organisational
                needs. <br /><br />We blend aspirational business goals, in-depth user
                needs, and digital innovation, to craft unique solutions that
                drive transformation and lead the charge into the future.
              </h1>
            </div>
          </div>

          <div className=" mb-8 ">
            {/* <button className="py-[12px] flex items-center gap-2 px-[16px] border-[1.5px] border-white rounded-[60px] text-white font-NeueMedium mt-8">
              Book a free consultation{" "}
              <FaArrowCircleRight className="text-white text-[18px]" />
            </button> */}
            <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
              Book a free consultation{" "}
              <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
              <span></span>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative  pb-[80px] md:pb-[100px]">
        <div className="max-w-[87.5rem] mx-auto">
          <div className="absolute z-20 md:pt-[120px] pt-[70px] top-[-350px] w-full overflow-hidden max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] rounded-[16px] md:rounded-[24px]">
            <video
              className="h-[200px] md:h-[430px] rounded-[16px] md:rounded-[24px] w-full object-cover"
              src={VidBg}
              autoPlay
              loop
              muted
            />
          </div>
        </div>
        <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-black to-[#45C4B0] opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] mb-[60px]"></div>
        <div className="relative z-20 max-w-[87.5rem] md:pt-[330px] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] flex flex-col md:flex-row md:gap-4 justify-between items-center md:items-start">
          <div className="md:w-[80%] w-full">
            <h1 className="text-[32px] md:text-[44px] pb-2 md:pb-3 font-NeueMedium text-white">
              You drive us
            </h1>
            <h1 className="text-[16px] md:text-[22px] text-[#A9A9A9] pb-2 md:pb-3 font-NeueRegular">
              At Eleven36, your needs and realities are our focus; We build
              everything else around you.
            </h1>
            <h1 className="text-[16px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
              Since inception, we’ve dedicated ourselves to redefining
              technology by building a central innovation hub for strategic
              disruptors to blur lines between diverse sectors & provide digital
              transformation.
            </h1>
          </div>
          <div className="relative w-full mt-4 rounded-[20px] md:mt-0">
            <video
              src={Drive}
              className="w-full rounded-[20px] h-auto"
              autoPlay
              loop
              muted
            />
          </div>
        </div>
      </div>
      <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] py-[100px]">
        <div>
          <h1 className="text-[44px] pb-6 font-NeueMedium text-white">
            Our core beliefs
          </h1>
          <div>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#45C4B0] flex flex-col items-start gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 min-h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#45C4B0] rounded-full bg-black p-3 inline-block transition-colors duration-300">
                    <IoMoveOutline />
                  </div>
                  <div className="relative mt-4 md:mt-10">
                    {" "}
                    {/* Adjusted margin-top for responsiveness */}
                    <h1 className="text-[20px] md:text-[24px] font-NeueMedium group-hover:text-black text-white transform transition-transform duration-300 group-hover:-translate-y-2">
                      Create a ripple
                    </h1>
                    <p className="text-white text-[18px] md:text-[22px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:text-black transition-all duration-300 ease-in-out mt-1">
                      Humanize technology and mold it to varying needs.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#FFF500] flex flex-col items-start gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 min-h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#FFF500] rounded-full bg-black p-3 inline-block transition-colors duration-300">
                    <BsFlag />
                  </div>
                  <div className="relative mt-4 md:mt-10">
                    <h1 className="text-[20px] md:text-[24px] font-NeueMedium group-hover:text-black text-white transform transition-transform duration-300 group-hover:-translate-y-2">
                      Inspire freedom
                    </h1>
                    <p className="text-white text-[18px] md:text-[22px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:text-black transition-all duration-300 ease-in-out mt-1">
                      Push boundaries, be limitless, and be fearless in the
                      pursuit of innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4 mt-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#FD8717] flex flex-col items-start gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 min-h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#FD8717] rounded-full bg-black p-3 inline-block transition-colors duration-300">
                    <HiOutlineUserGroup />
                  </div>
                  <div className="relative mt-4 md:mt-10">
                    <h1 className="text-[20px] md:text-[24px] font-NeueMedium group-hover:text-black text-white transform transition-transform duration-300 group-hover:-translate-y-2">
                      Nurture a community
                    </h1>
                    <p className="text-white text-[18px] md:text-[22px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:text-black transition-all duration-300 ease-in-out mt-1">
                      Build a community of strategic disruptors always ready to
                      navigate new waters.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#C7FFED] flex flex-col items-start gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 min-h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#C7FFED] rounded-full bg-black p-3 inline-block transition-colors duration-300">
                    <FaRegStar />
                  </div>
                  <div className="relative mt-4 md:mt-10">
                    <h1 className="text-[20px] md:text-[24px] font-NeueMedium group-hover:text-black text-white transform transition-transform duration-300 group-hover:-translate-y-2">
                      Serve to impact
                    </h1>
                    <p className="text-white text-[18px] md:text-[22px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:text-black transition-all duration-300 ease-in-out mt-1">
                      Adopt an attitude that demystifies technology and focuses
                      on human goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <IndustryExpertise />

      <WhyEleven36 />
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default AboutUs;
