import { useState } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
// import VidBg from "../assets/images/footervid.mp4";
import { Link } from "react-router-dom";
const ContactUsForm = () => {
  const [phone, setPhone] = useState("");

  return (
    <div className="text-white">
      <div className="bg-gradient-to-b py-[80px] md:py-[140px] from-[#2A8476] to-[#003551]">
        <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem]">
          <div className="backdrop-blur-md flex flex-col lg:flex-row gap-8 lg:gap-[60px] items-stretch justify-between bg-white bg-opacity-10 rounded-[20px] p-4 md:p-[16px]">
            <div className="w-full p-4 lg:p-7">
              <h1 className="font-NeueMedium text-[20px] lg:text-[24px] pb-1">
                Do you need a digital transformation?
              </h1>
              <h1 className="text-[12px] lg:text-[13px] font-NeueMedium">
                Fill the form and get a FREE consultation!
              </h1>

              <form style={{ paddingTop: "20px" }} className="pt-[30px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pb-3">
                  <input
                    type="text"
                    name="Name"
                    className="bg-transparent py-[9px] bg-white bg-opacity-5 rounded-[5px] border border-white border-opacity-30 w-full outline-none placeholder:text-white placeholder:text-opacity-70 text-white text-[13px] font-NeueRegular px-[14px]"
                    placeholder="Name"
                  />
                  <PhoneInput
                    defaultCountry="ng"
                    value={phone}
                    inputStyle={{ padding: "12px" }}
                    onChange={(phone) => setPhone(phone)}
                  />
                  <input
                    type="email"
                    name="Email"
                    className="bg-transparent py-[9px] bg-white bg-opacity-5 rounded-[5px] border border-white border-opacity-30 w-full outline-none placeholder:text-white placeholder:text-opacity-70 text-white text-[13px] font-NeueRegular px-[14px]"
                    placeholder="Email Address"
                  />
                  <input
                    type="text"
                    name="Company Name"
                    className="bg-transparent py-[9px] bg-white bg-opacity-5 rounded-[5px] border border-white border-opacity-30 w-full outline-none placeholder:text-white placeholder:text-opacity-70 text-white text-[13px] font-NeueRegular px-[14px]"
                    placeholder="Company Name"
                  />
                </div>
                <h1 className="text-[13px] pt-1 font-NeueMedium">
                  What services do you need?
                </h1>
                <div className="flex flex-wrap items-center py-2 gap-5">
                  <div className="flex items-center gap-2">
                    <input type="checkbox" id="Consultancy" name="Services" />
                    <label
                      htmlFor="Consultancy"
                      className="text-white text-[13px] font-NeueRegular"
                    >
                      Consultancy
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="checkbox" id="Ideation" name="Services" />
                    <label
                      htmlFor="Ideation"
                      className="text-white text-[13px] font-NeueRegular"
                    >
                      Ideation
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="checkbox" id="Design" name="Services" />
                    <label
                      htmlFor="Design"
                      className="text-white text-[13px] font-NeueRegular"
                    >
                      Design
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="software-development"
                      name="Services"
                      value="software-development"
                    />
                    <label
                      htmlFor="software-development"
                      className="text-white text-[13px] font-NeueRegular"
                    >
                      Software Development
                    </label>
                  </div>
                </div>
                <textarea
                  rows={4}
                  placeholder="Message"
                  className="bg-transparent resize-none py-[12px] my-2 bg-white bg-opacity-5 rounded-[5px] border border-white border-opacity-30 w-full outline-none placeholder:text-white placeholder:text-opacity-70 text-white text-[13px] font-NeueRegular px-[14px]"
                />
                {/* <button className="py-[12px] flex items-center gap-2 px-[16px] border-[1.5px] border-white rounded-[60px] text-white font-NeueMedium">
                  Submit form
                  <FaArrowCircleRight className="text-white text-[18px]" />
                </button> */}
                <Link className="btn-5 group font-NeueMedium" to="#">
                  Submit form{" "}
                  <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
                  <span></span>
                </Link>
              </form>
            </div>
            {/* <div className="min-h-full min-w-[200px] lg:min-w-[400px] w-full lg:w-[40%] rounded-[12px]">
              <video
                className="h-[200px] min-h-full md:h-[430px] min-w-[200px] rounded-[12px] w-full object-cover"
                src={VidBg}
                autoPlay
                loop
                muted
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
