import { FaArrowCircleRight, FaArrowUp } from "react-icons/fa";
import HowWeServeYou from "../components/HowWeServeYou";
import { useEffect, useState } from "react";
// import HeroImg from "../assets/images/GroupPattern.svg";
import HeroImg from "../assets/images/servicehero.png";
import { Link } from "react-router-dom";
const Services = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="z-50">
      <div className="relative overflow-hidden h-[90vh]">
        <img
          src={HeroImg}
          className="absolute top-0 left-[33%] bottom-0 w-full h-full object-contain z-30"
          alt=""
        />
        <div className="absolute inset-0 w-full top-0 left-0 right-0 bottom-0 h-full radial-gradient-bg3 bg-opacity-5 z-10"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-20"></div>
        <div className="relative z-30 max-w-[87.5rem] h-full flex flex-col mt-6 sm:mx-auto justify-center px-[1rem] md:px-[2rem] lg:px-[3rem]">
          <div>
            <div className="w-full md:w-[60%] mb-4 md:mb-0">
              <h1 className="text-[32px] md:text-[44px] pb-3 font-NeueMedium text-white">
                Every digital service you need to start is right here
              </h1>
              <h1 className="text-[18px] md:text-[22px] text-[#A9A9A9] pb-3 font-NeueRegular">
                Through every phase, and scale of your journey, we are committed
                to providing custom digital solutions that address your current
                challenges, while ensuring sustainable growth.
              </h1>
            </div>
          </div>
          <div>
            <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
              Book a free consultation{" "}
              <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
              <span></span>
            </Link>
          </div>
        </div>
      </div>
      <HowWeServeYou />
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default Services;
