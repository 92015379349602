import { useState } from "react";
import { Link } from "react-router-dom";
import Frame1 from "../assets/images/footerframe.svg";
import NullFrame from "../assets/images/footernull.png";
const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div>
      <div className="bg-[#0F0F0F] py-[80px] relative" onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
        <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-[60px] justify-between mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] max-w-[87.5rem]">
          <div className="w-full lg:w-1/2">
            <h1 className="text-[12px] text-[#828D91] font-NeueRegular mb-7">
              MENU
            </h1>
            <div>
              <div className="flex flex-wrap items-center gap-3">
                <Link to="/about-us">
                  <h1 className="text-[18px] lg:text-[23px] hover:text-[#45C4B0] text-white font-NeueRegular">
                    About us
                  </h1>
                </Link>
                <h1 className="text-[#828D91] text-[20px] lg:text-[26px] font-NeueThin">
                  /
                </h1>
                <Link to="/expertise">
                  <h1 className="text-[18px] lg:text-[23px] hover:text-[#45C4B0] text-white font-NeueRegular">
                    Expertise
                  </h1>
                </Link>
                <h1 className="text-[#828D91] text-[20px] lg:text-[26px] font-NeueThin">
                  /
                </h1>
                <Link to="/services">
                  <h1 className="text-[18px] lg:text-[23px] hover:text-[#45C4B0] text-white font-NeueRegular">
                    Services
                  </h1>
                </Link>
                <h1 className="text-[#828D91] text-[20px] lg:text-[26px] font-NeueThin">
                  /
                </h1>
                <Link to="/portfolio">
                  <h1 className="text-[18px] lg:text-[23px] hover:text-[#45C4B0] text-white font-NeueRegular">
                    Portfolio
                  </h1>
                </Link>
                <h1 className="text-[#828D91] text-[20px] lg:text-[26px] font-NeueThin">
                  /
                </h1>
                <h1 className="text-[18px] lg:text-[23px] text-white font-NeueRegular">
                  Tech stack
                </h1>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <h1 className="text-[12px] mb-7 text-[#828D91] font-NeueRegular">
              CONTACT US
            </h1>
            <div className="">
              <a href="tel:+2349054276585">
                <h1 className="text-[15px] hover:text-[#45C4B0] text-[#828D91] mb-3 font-NeueRegular">
                  +234 905 427-65-85
                </h1>
              </a>
              <a href="tel:+2349169525803">
                <h1 className="text-[15px] hover:text-[#45C4B0] text-[#828D91] mb-3 font-NeueRegular">
                  +234 916 952-58-03
                </h1>
              </a>
              <a href="mailto:info@eleven36.com.ng ">
                <h1 className="text-[15px] text-[#828D91] hover:text-[#45C4B0] mb-3 font-NeueRegular">
                  info@eleven36.com.ng
                </h1>
              </a>
              <h1 className="text-[15px] text-[#828D91] font-NeueRegular">
                Abuja: No 4. Gwarjo Close, off Gimbiya Street,
              </h1>
              <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
                Garki Area 11, FCT-Abuja.
              </h1>
              <h1 className="text-[15px] text-[#828D91] font-NeueRegular">
                Lagos: The Zone
              </h1>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <h1 className="text-[12px] mb-7 text-[#828D91] font-NeueRegular">
              FOLLOW US
            </h1>
            <div className="">
              <Link to="https://x.com/eleven36ng" target="_blank">
                <h1 className="text-[15px] text-white mb-3 font-NeueRegular">
                  Twitter
                </h1>
              </Link>
              <Link
                to="https://www.linkedin.com/company/eleven36"
                target="_blank"
              >
                <h1 className="text-[15px] text-white mb-3 font-NeueRegular">
                  Linkedin
                </h1>
              </Link>
              <Link to="https://www.instagram.com/eleven36.ng" target="_blank">
                <h1 className="text-[15px] text-white mb-3 font-NeueRegular">
                  Instagram
                </h1>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="absolute z-20 bottom-0 right-0 minimd:block hidden"
        >
          <img
            className="opacity-100 translate-y-0 transition-all duration-[1300ms] w-[100%]"
            src={isHovered === true ? Frame1 : NullFrame}
            alt="glow"
          />
        </div>
      </div>

      <div className="bg-black pb-3 pt-6 mx-auto max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem] flex flex-col lg:flex-row items-center justify-between">
        <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
          © {new Date().getFullYear()} — Copyright Eleven36.
        </h1>
        <div className="flex items-center gap-6">
          <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
            Blog
          </h1>
          <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
            Contact
          </h1>
        </div>
        <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
          All rights reserved
        </h1>
      </div>
      {/* <div className="flex flex-col lg:flex-row items-start lg:items-end mt-[60px] space-y-8 lg:space-y-0 lg:space-x-[60px] justify-between mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] max-w-[87.5rem]">
          <div className="w-full lg:w-1/2 flex flex-wrap lg:items-center gap-6">
            <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
              Blog
            </h1>
            <h1 className="text-[15px] text-[#828D91] mb-3 font-NeueRegular">
              Contact
            </h1>
          </div>
          <div className="w-full lg:w-1/2">
            <h1 className="text-[12px] mb-7 text-[#828D91] font-NeueRegular">
              FOLLOW US
            </h1>
            <div className="">
              <Link to="https://x.com/eleven36ng" target="_blank">
                <h1 className="text-[15px] text-white mb-3 font-NeueRegular">
                  Twitter
                </h1>
              </Link>
              <Link
                to="https://www.linkedin.com/company/eleven36"
                target="_blank"
              >
                <h1 className="text-[15px] text-white mb-3 font-NeueRegular">
                  Linkedin
                </h1>
              </Link>
              <Link to="https://www.instagram.com/eleven36.ng" target="_blank">
                <h1 className="text-[15px] text-white mb-3 font-NeueRegular">
                  Instagram
                </h1>
              </Link>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default Footer;
