import { FaArrowUp, FaEnvelope } from "react-icons/fa";
import HeroImg from "../assets/images/medichero.png";
// import HeroImg from "../assets/images/GroupPattern.svg";
import Phone2 from "../assets/images/handphone.svg";
import Ars from "../assets/images/Astronaut with space shuttle 1.gif";
import { IoIosAddCircle, IoMdCheckmark } from "react-icons/io";
import App from "../assets/images/app.svg";
import Db from "../assets/images/db.svg";
import Reactlogo from "../assets/images/react.svg";
import Phone from "../assets/images/solution-img.svg";
import Logo from "../assets/images/Group (1).svg";
import { IoCard } from "react-icons/io5";
import Infra from "../assets/images/infra.svg";
import Inter from "../assets/images/Internet.svg";
import Security from "../assets/images/Security.svg";

import { useEffect, useState } from "react";
const ElectronicMedical = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="z-50">
      <div className="relative py-[100px] md:h-[95vh] h-[88vh] overflow-hidden">
        <img
          src={HeroImg}
          className="absolute top-0 left-[20%] bottom-0 w-full h-full object-contain z-20"
          alt=""
        />
        {/* <img
          src={HeroImg}
          className="absolute top-0 left-[40%] bottom-0 w-full h-full object-cover z-20 container"
          alt=""
        /> */}
        <div className="absolute inset-0 w-full h-full opacity-40 radial-gradient-bg6 z-10"></div>

        <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem]"></div>

        <div className="relative z-20 max-w-[87.5rem] mx-auto md:pt-0 pt-[100px] px-4 md:px-[3rem] lg:px-[5rem] h-full">
          <div className="w-full flex flex-col md:flex-row justify-between h-full">
            {/* Left Section */}
            <div className="w-full md:w-[55%] flex flex-col justify-center text-start md:text-left">
              <h1 className="text-white font-NeueMedium w-full text-[24px] md:text-4xl lg:text-5xl">
                Electronic medical records
              </h1>
              <h1 className="font-NeueMedium text-[20px] md:text-[22px] text-white mt-6 md:mt-8">
                Services:
              </h1>
              <h1 className="text-white font-NeueRegular w-full md:w-[80%] lg:w-[60%] text-sm md:text-base lg:text-lg mx-auto md:mx-0">
                Discovery phase, UI/UX design, Web & Mobile app development
              </h1>
            </div>

            {/* Right Section (Image) */}
            <div className="w-full md:w-[45%] h-full md:flex hidden items-center mt-8 md:mt-0">
              <img
                src={Phone}
                alt="Digital marketplace phone"
                className="w-[80%] md:w-full h-[800px] object-contain mx-auto scale-x-[-1]"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-white pt-[100px] mx-auto max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem]">
        <div className="flex">
          <div className="border-r border-[#FFFFFF52] inline-block pr-[50px]">
            <h1 className="font-NeueMedium text-[22px]">Industry:</h1>
            <h2 className="text-[15px] font-NeueRegular text-[#E7E7E7]">
              Healthcare
            </h2>
          </div>
          <div className="pl-[50px] inline-block">
            <h1 className="font-NeueMedium text-[22px]">Team:</h1>
            <h2 className="text-[15px] font-NeueRegular text-[#E7E7E7]">
              11 Specialists
            </h2>
          </div>
        </div>
        <div className="inline-block pt-7">
          <h1 className="font-NeueMedium md:text-[22px] sm:text-[20px] text-[18px]">
            Project description:
          </h1>
          <h2 className="sm:text-[15px] text-[13px] font-NeueRegular sm:w-[60%] w-full text-[#E7E7E7]">
            A comprehensive electronic medical records (eMR) platform designed
            for healthcare management, patient data integration, secure access
            to health records, efficient appointment scheduling, and real-time
            health monitoring.
          </h2>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between sm:gap-[90px] gap-[45px] sm:text-start text-center pb-6 pt-[60px]">
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              6 months
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              For Full-feature Web & App
            </h2>
          </div>
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              400+
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              Downloads in the first month of Launch
            </h2>
          </div>
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              4.9
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              Combined store rating
            </h2>
          </div>
        </div>
      </div>

      <div className="py-[80px] sm:py-[100px] px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem]">
        <h1 className="font-NeueMedium md:pb-0 pb-6  text-[28px] sm:text-[36px] md:text-[44px] text-white">
          About this project
        </h1>
        <div className="md:flex block items-start justify-between">
          <div className="w-[40%]"></div>
          <div className="md:w-[60%]">
            <div className="pb-7 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#45C4B0]">
                01
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The partner
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  Our partner, a renowned hospital with a high volume of
                  patients, faces significant challenges in managing patient
                  records efficiently. The current paper-based system results in
                  time-consuming processes, errors in record-keeping, and
                  difficulty accessing patient information in emergencies. This
                  manual approach not only hampers the hospital staff's
                  productivity but also poses risks to patient safety and
                  quality of care.
                </p>
              </div>
            </div>

            <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C]">
                02
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The big picture
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  To implement a modern Electronic Medical Records (EMR) system
                  to streamline operations, improve data accuracy, and enhance
                  patient care delivery.
                </p>
              </div>
            </div>

            <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00]">
                03
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The partner’s objectives
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  The partner aimed to empower customers with seamless access to
                  their purchase history, the ability to request orders, track
                  deliveries, and access product information round the clock.
                </p>
                <h1 className="text-[16px] pt-3 sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  They sought to offer features for customers to renew
                  subscriptions, manage payment methods, and complete
                  transactions conveniently online. They sought to strategically
                  scale in a manner that gives room for sustainable growth
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[87.5rem] mx-auto px-4 md:px-[3rem] lg:px-[5rem] py-[100px]">
        <div>
          <h1 className="text-[44px] pb-6 font-NeueMedium text-white">
            Our process
          </h1>
          <div>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#45C4B0] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#45C4B0] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>1</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] font-NeueMedium group-hover:hidden text-white transform transition-all duration-300">
                      Ideation
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      The product and manager studied the project requirements,
                      developed a shared vision of the project, prepared a
                      backlog, setup tools, a project environment, and a
                      database.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#FFF500] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#FFF500] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>2</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] whitespace-nowrap font-NeueMedium text-white group-hover:hidden transform transition-all duration-300">
                      Strategic analysis
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      Our product and business analysts studied the project
                      requirements and provided software requirements and
                      specifications. Created high-level technical
                      specifications based on partner's requirements and
                      business objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4 mt-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#FD8717] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#FD8717] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>3</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] font-NeueMedium whitespace-nowrap group-hover:hidden text-white transform transition-all duration-300">
                      Technical development
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      The Software engineers studied project requirements,
                      planned and described the architecture of the mobile and
                      web app.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#C7FFED] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#C7FFED] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>4</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] font-NeueMedium group-hover:hidden text-white transform transition-all duration-300">
                      Design
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      Our Designer created an e-commerce mobile app design
                      concept based on the project requirements, brand book, and
                      partner's comment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#033A5499] py-[80px] overflow-hidden portfolio-bg md:py-[100px]">
        <div className="px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto flex relative flex-col md:flex-row items-center max-w-[87.5rem]">
          <div className="w-full md:w-[50%] mb-5 md:mb-0">
            <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
              Project mission
            </h1>
            <div className="pb-5">
              <p className="text-[#C7C7C7] md:text-[18px] text-[16px] font-NeueRegular">
                To collaborate closely with our esteemed healthcare partner,
                leveraging our technical expertise to transform their vision
                into reality. We set out to:
              </p>
            </div>
            <ul className="list-disc pl-5">
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Design a Patient-Centric EMR Interface:
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Our team designed intuitive and accessible interfaces focused
                  on enhancing user experience for all stakeholders – healthcare
                  providers, administrative staff, and patients.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Develop a Tailored EMR Solution:
                <span className="text-[#C7C7C7] font-NeueRegular">
                  We created a scalable and secure Electronic Medical Records
                  system customized to address the specific challenges and
                  workflows of the hospital.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Establish Robust Admin Tools:
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Our developers equipped the EMR with advanced admin panels
                  that enable efficient management of patient data, facilitate
                  seamless healthcare operations, and ensure compliance with
                  health regulations.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Integrate with Existing Hospital Systems:
                <span className="text-[#C7C7C7] font-NeueRegular">
                  We ensured a seamless integration of the EMR with existing
                  hospital infrastructure to support interoperability, enhance
                  data exchange, and improve workflow efficiency.
                </span>
              </li>
            </ul>
          </div>
          <div className="w-full absolute bottom-[-200px] right-0 hidden md:w-[50%] md:flex justify-center">
            <img src={Phone2} alt="phone" className="w-[400px]" />
          </div>
        </div>
      </div>

      <div className="relative md:py-[100px] py-[80px] ">
        <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="max-w-[87.5rem] mx-auto lg:px-[3rem] md:px-[2rem] px-[1rem]">
          <h1 className="text-white relative z-20 text-center md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            The Solution
          </h1>
          <div className="flex justify-end items-center z-20 relative pb-[80px] pt-[100px]">
            <img
              className="absolute md:block hidden left-[-40px] w-[60%] z-10"
              src={Phone}
              alt="phone"
            />
            <div className="bg-[#1C1C1C] md:w-[55%] w-full rounded-[20px] p-[25px] md:ml-[25%] z-20 relative">
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                We developed a user-friendly mobile application for customers
                and an intuitive web application for merchants, complemented by
                a robust admin panel.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                Each part seamlessly interacts with each other, ensuring smooth
                operation and enhanced user experience.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                The developed database securely stores information and
                synchronizes data with the website and partner data storage
                systems, ensuring reliability and consistency across all
                platforms.
              </h1>
            </div>
          </div>
          <hr className="text-[#FFFFFF29] w-full" />
        </div>

        <div className="relative z-20 max-w-[87.5rem] pt-[50px] mx-auto px-4 sm:px-8 md:px-[5rem]">
          <h1 className="text-white text-[24px] md:text-[32px] lg:text-[40px] pb-5 font-NeueMedium">
            Strategic advantages
          </h1>
          <div className="flex flex-col md:flex-row justify-between gap-6 md:gap-4">
            <div className="w-full md:w-1/2">
              <h1 className="text-[#45C4B0] pb-3 text-[20px] md:text-[24px] font-NeueBold">
                Rapid Response Functionality
              </h1>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] pb-3 font-NeueRegular">
                Our EMR system provides instant access to essential patient
                details during emergencies, featuring a streamlined interface
                for quick navigation and comprehensive patient histories.
              </p>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] font-NeueRegular">
                It supports real-time updates, ensuring healthcare providers
                make informed, coordinated decisions swiftly, improving outcomes
                and saving lives.
              </p>
            </div>

            <div className="w-full md:w-1/2">
              <h1 className="text-[#45C4B0] pb-3 text-[20px] md:text-[24px] font-NeueBold">
                Interoperability and Integration with Healthcare Systems
              </h1>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] pb-3 font-NeueRegular">
                Our EMR solution features high interoperability, connecting
                seamlessly with diverse healthcare systems like pharmacies,
                labs, and radiology departments.
              </p>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] font-NeueRegular">
                This integration provides a unified platform for holistic
                patient records, essential for coordinated care and effective
                treatment planning. It enhances healthcare efficiency and
                reduces manual data entry errors.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[50px] md:py-[100px]">
        <div className="lg:px-[3rem] md:px-[2rem] px-[1rem] mx-auto max-w-[87.5rem]">
          <h1 className="text-white md:text-[40px] text-[24px] pb-5 font-NeueMedium text-left">
            Platform essential
          </h1>
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="bg-[#45C4B0] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Clinical Modules
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                Automated clinical notes with click based suggestions. Access to
                tailored outpatient A & E, ward & specialty modules, Visuals of
                graphs of vital signs and test results.
              </p>
            </div>
            <div className="bg-[#FFDA2C] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Speech to Text
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                The solution includes speech-to-text technology for convenient
                and efficient data entry through hands free dictation of
                clinical notes and patient records.
              </p>
            </div>
            <div className="bg-[#FF7B00] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Pharmacy
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                The EMR links medications and consumables to patient records:
                Manages stores efficiently to enable monitoring of prescriptions
                & grow revenue.
              </p>
            </div>
            <div className="bg-[#C7FFED] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Clinical Suggestions
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                The solution provides intelligent clinical suggestions for
                healthcare professionals. Based on advanced algorithms and
                patients data. it offers personalised recommendations, alert and
                reminders
              </p>
            </div>
            <div className="bg-[#003551] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-white pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Receptionist
              </h1>
              <p className="text-white text-[14px] md:text-[18px]">
                The EMR efficiently handles appointments, patient insurance
                information and reduce patient wait times.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative py-[50px] md:py-[100px]">
        <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="mx-auto relative z-20 max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem]">
          <h1 className="text-white text-[24px] md:text-[40px] pb-5 font-NeueMedium text-left">
            User adaptations
          </h1>
          <div>
            <div className="pb-5 border-b border-[#FFFFFF29] space-y-10 md:space-y-0 md:flex md:justify-between">
              <div className="flex space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#45C4B0]">
                  01
                </h1>
                <div className="mt-5">
                  <h1 className="text-[20px] sm:text-[24px] font-NeueMedium text-[#45C4B0]">
                    For customers
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Account Registration/Login</li>
                    <li>Account Balance Management</li>
                    <li>Account security set up</li>
                    <li>Funds transactions</li>
                    <li>Transaction History</li>
                    <li>Loan Applications</li>
                    <li>Savings Management</li>
                    <li>Bill Payments</li>
                    <li>Setting Alerts</li>
                    <li>Customer Support</li>
                  </ul>
                </div>
              </div>
              <div className="flex space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C]">
                  02
                </h1>
                <div className="mt-5">
                  <h1 className="text-[20px] sm:text-[24px] font-NeueMedium text-[#FFDA2C]">
                    For bank employees
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Employee Registration/Login</li>
                    <li>Account security set up</li>
                    <li>Customer Account Management</li>
                    <li>Transaction Oversight</li>
                    <li>Loan Approval Processes</li>
                    <li>Savings Advisory</li>
                    <li>Regulatory Compliance</li>
                    <li>Customer Support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex justify-end relative mt-10 md:mt-0 items-start">
              <div className="absolute left-0 bottom-0">
                <img src={Logo} className="w-[60%]" alt="logo" />
              </div>
              <div className="flex space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00]">
                  03
                </h1>
                <div className="mt-5">
                  <h1 className="text-[20px] sm:text-[24px] font-NeueMedium text-[#FF7B00]">
                    For admins
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Admin Registration/Login</li>
                    <li>Account security set up</li>
                    <li>Admin Dashboard</li>
                    <li>Customer Account Management</li>
                    <li>Employee profile management</li>
                    <li>Financial Product Management</li>
                    <li>Transaction Auditing</li>
                    <li>Analytics and Reporting</li>
                    <li>System Customization</li>
                    <li>Communication and support system</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[100px]">
        <div className="px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem]">
          <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            Integrations
          </h1>
          <div>
            <div className="flex flex-wrap gap-4 sm:gap-6 w-full">
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosAddCircle className="text-black text-[18px]" />
                </div>
                SNOMED CT (Systematised Nomenclature of Medicine - Clinical
                Terms) Integration
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosAddCircle className="text-black text-[18px]" />
                </div>
                HL7 (Health Level Seven) Healthcare data exchange including HL7
                v2 abd HL7 FHIR (Fast Healthcare Interoperability Resources)
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosAddCircle className="text-black text-[18px]" />
                </div>
                Medical Device Integration: Integrates with vital sign monitor,
                ECG machines, and infusion pumps, to capture and record patient
                data directly into the EMR.
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosAddCircle className="text-black text-[18px]" />
                </div>
                DICOM (Digital Imagine and Communication in Medicine )
                Integration
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoCard className="text-black text-[18px]" />
                </div>
                Payment gateways
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosAddCircle className="text-black text-[18px]" />
                </div>
                Lab Information System (LIS) Integration
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaEnvelope className="text-black text-[18px]" />
                </div>
                Email and sms integration
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[80px] relative md:py-[100px]">
        <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-black to-[#45C4B0] opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="px-[1rem] relative z-20 md:px-[2rem] lg:px-[3rem] max-w-[87.5rem] mx-auto">
          <h1 className="text-white text-2xl md:text-4xl font-NeueMedium mb-8 text-center md:text-left">
            Ultimate results from the partnership
          </h1>
          <div className="flex flex-col md:flex-row w-full items-center">
            <div className="w-full md:w-[40%] mb-8 md:mb-0">
              <img
                src={Ars}
                className="transform scale-x-[-1] filter grayscale mx-auto"
                alt="astronaut"
              />
            </div>
            <div className="flex flex-col w-full md:w-[60%]">
              {[
                "Enhanced Patient Care through quicker and more efficient access to patient data.",
                "Improved Data Security and Compliance Through secure management and compliance with health regulation.",
                "Boosted Interoperability Within Healthcare Ecosystem through  integration  with pharmacies, labs, and radiology departments.",
                "Enhanced Decision-Making Capabilities through real-time data update feature and accessibility of complete patient histories.",
                "Reduced Operational Costs through automations  and reducing reliance on manual paper records and management.",
                "Improved Patient Satisfaction through easier access to patient health information.",
              ].map((text, index) => (
                <div
                  key={index}
                  className="border-b border-[#FFFFFF29] py-3 flex items-center gap-5"
                >
                  <div className="h-[30px] w-[30px] rounded-full bg-[#45C4B0] flex items-center justify-center flex-shrink-0">
                    <IoMdCheckmark className="text-[18px]" />
                  </div>
                  <p className="text-white text-base md:text-lg font-NeueBold">
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="md:py-[100px] py-[80px]">
        <div className="lg:px-[3rem] md:px-[2rem] px-[1rem] max-w-[87.5rem] mx-auto">
          <h1 className="text-white pb-8 md:text-4xl text-2xl font-NeueMedium">
            Technology stack
          </h1>

          <div className="grid grid-cols-3 gap-6 justify-between">
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#C7FFED] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Db} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Backend
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Java</li>
                    <li>Spring Boot</li>
                    <li>PostgreSQL</li>
                    <li>OAuth 2.0</li>
                    <li>RESTful APIs</li>
                    <li>ORM (Object-Relational Mapping)</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="group hover:bg-[#FF7B00] rounded-[24px] pt-6 px-6 pb-9 bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Reactlogo} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Frontend
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Redux</li>
                    <li>React.js</li>
                    <li>CSS</li>
                    <li>Bootstrap</li>
                    <li>Redux</li>
                    <li>React Router</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={App} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Mobile app
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>React Native</li>
                    <li>Figma</li>
                    <li>Redux</li>
                    <li>React Navigation</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Infra} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Infrastructure
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Microsoft Azure </li>
                    <li>Docker </li>
                    <li>Jenkins </li>
                    <li>Grafana</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Security} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Security
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>AES encryption </li>
                    <li>SSL/TLS</li>
                    <li>OWASP Security Practices</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Inter} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Interoperability
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>HL7 Integration: </li>
                    <li>FHIR (Fast Healthcare Interoperability Resources)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default ElectronicMedical;
