import { useEffect, useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { FaArrowCircleRight, FaArrowUp } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import { GiGraduateCap } from "react-icons/gi";
import { HiMiniCpuChip } from "react-icons/hi2";
import { IoIosCube, IoMdAirplane } from "react-icons/io";
import { IoWalletSharp } from "react-icons/io5";
import { PiHospitalFill } from "react-icons/pi";
import Ars from "../assets/images/Astronaut with space shuttle 1.gif";
import { Link } from "react-router-dom";
const Expertise = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);
  return (
    <div className="z-50">
      <div className="relative sm:h-[90vh] h-[80vh] expert-bg z-0 overflow-hidden">
        <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-black to-[#45C4B0] opacity-30 z-10"></div>
        <div className="relative z-20 max-w-[87.5rem] h-full flex flex-col sm:flex-row mt-6 sm:mx-auto sm:justify-between justify-center items-center px-[1rem] md:px-[2rem] lg:px-[3rem]">
          <div className="w-full md:w-[50%]">
            <h1 className="text-[28px] md:text-[36px] lg:text-[44px] pb-3 font-NeueMedium text-white">
              Limitless digital innovation that powers diverse industries
            </h1>
            <h1 className="text-[16px] md:text-[18px] lg:text-[22px] text-[#A9A9A9] pb-3 font-NeueRegular">
              Experience unparalleled cross-industry innovations that bridge
              sectors to forge new frontiers in technology, producing novel
              solutions that redefine digital transformation.
            </h1>
            <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
              Book a free consultation{" "}
              <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
              <span></span>
            </Link>
          </div>

          <div className="w-full md:w-[50%] sm:flex hidden justify-center mb-8 md:mb-0">
            <img
              src={Ars}
              className="filter grayscale max-w-full h-auto"
              alt="astronaut"
            />
          </div>
        </div>
      </div>

      <div className="text-white py-[100px] mx-auto max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem]">
        <h1 className="font-NeueMedium text-[32px] md:text-[44px] text-white">
          Industry Expertise
        </h1>
        <h1 className="font-NeueRegular pt-2 pb-4 text-[18px] md:text-[23px] text-white">
          There’s something for everyone
        </h1>
        <div className="flex flex-col md:flex-row pt-7">
          <div className="grid grid-cols-1 xsm:grid-cols-3 gap-6 w-full">
            <div
              onMouseEnter={() => setShowFirst(true)}
              onMouseLeave={() => setShowFirst(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer hover:text-black mb-4 hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <IoWalletSharp className="text-black text-[18px]" />
                </div>
                Finance
              </div>
              {showFirst && (
                <ul className="text-[#CDCDCD] ml-4 list-disc">
                  <li className="text-[17px] font-NeueRegular">
                    Digital banking and neobank application
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Savings and Investment application
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Payment collection & merchant acquiring platform
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Digital Insurance application (customer & agents)
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    ⁠E-wallet solutions
                  </li>
                </ul>
              )}
            </div>

            {/* E-commerce Section */}
            <div
              onMouseEnter={() => setShowFirst(true)}
              onMouseLeave={() => setShowFirst(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <IoIosCube className="text-black text-[18px]" />
                </div>
                E-commerce
              </div>
              {showFirst && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    Retail Marketplace platforms
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Store inventory software solutions
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Order management systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Point-of-sale(POS) system
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    AI-driven demand forecasting
                  </li>
                </ul>
              )}
            </div>

            {/* Proptech Section */}
            <div
              onMouseEnter={() => setShowFirst(true)}
              onMouseLeave={() => setShowFirst(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <HiMiniCpuChip className="text-black text-[18px]" />
                </div>
                Proptech
              </div>
              {showFirst && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    Property monitoring systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Real estate market automation
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Geolocation-based marketing
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Data-powered market analysis
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Automated tenant systems
                  </li>
                </ul>
              )}
            </div>

            {/* Education Section */}
            <div
              onMouseEnter={() => setShowSecond(true)}
              onMouseLeave={() => setShowSecond(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <GiGraduateCap className="text-black text-[18px]" />
                </div>
                Education
              </div>
              {showSecond && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    eLearning platforms
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Gamification features
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Educational analytics platforms
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Mobile learning applications
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Digital assessment systems
                  </li>
                </ul>
              )}
            </div>

            {/* Healthcare Section */}
            <div
              onMouseEnter={() => setShowSecond(true)}
              onMouseLeave={() => setShowSecond(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <PiHospitalFill className="text-black text-[18px]" />
                </div>
                Healthcare
              </div>
              {showSecond && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    Electronic Health Record (EHR) systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Telemedicine services
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Digital clinical informatics tools
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Clinical decision support systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Patient portals
                  </li>
                </ul>
              )}
            </div>

            {/* Transport & Logistics Section */}
            <div
              onMouseEnter={() => setShowSecond(true)}
              onMouseLeave={() => setShowSecond(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <FaTruckFast className="text-black text-[18px]" />
                </div>
                Transport & Logistics
              </div>
              {showSecond && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    Logistics coordination systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Inventory control systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Ride-hailing systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Car Rental Apps
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Supply Chain Management Software
                  </li>
                </ul>
              )}
            </div>

            {/* Traveling & Ticketing Section */}
            <div
              onMouseEnter={() => setShowThird(true)}
              onMouseLeave={() => setShowThird(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <IoMdAirplane className="text-black text-[18px]" />
                </div>
                Traveling & Ticketing
              </div>
              {showThird && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    Online booking systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Travel itinerary apps
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Ticketing platforms
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Travel management solutions
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Travel Expense Tracking Tools
                  </li>
                </ul>
              )}
            </div>
            <div
              onMouseEnter={() => setShowThird(true)}
              onMouseLeave={() => setShowThird(false)}
            >
              <div className="py-[18px] hover:bg cursor-pointer mb-4 hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium text-[18px] sm:text-[20px]">
                <div className="bg-white p-1 rounded-full">
                  <BsTelephoneFill className="text-black text-[18px]" />
                </div>
                Telecommunication
              </div>
              {showThird && (
                <ul className="text-[#CDCDCD] list-disc ml-4">
                  <li className="text-[17px] font-NeueRegular">
                    IPTV and OTT software development
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Social media and streaming app creation
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Client engagement software
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Infrastructure development systems
                  </li>
                  <li className="text-[17px] font-NeueRegular">
                    Social media integration platforms
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default Expertise;
