import { IoIosCube } from "react-icons/io";
import { IoWalletSharp } from "react-icons/io5";
import { HiMiniCpuChip } from "react-icons/hi2";
import { GiGraduateCap } from "react-icons/gi";
import { PiHospitalFill } from "react-icons/pi";
import { FaTruckFast } from "react-icons/fa6";
import { IoMdAirplane } from "react-icons/io";
import { BsTelephoneFill } from "react-icons/bs";
import NullFrame from "../assets/images/nullframe.png";
import Frame1 from "../assets/images/industframe.svg";
import { useState } from "react";
const IndustryExpertise = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="relative text-white sm:pt-[100px] sm:pb-[160px]" onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}>
      <div
        className={`absolute inset-0 w-full h-full ${
          isHovered ? "radial-gradient-bg7 " : ""
        } opacity-40 z-0`}
      ></div>
      <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
      <div className="z-20 relative mx-auto max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem]">
        <h1 className="font-NeueMedium text-[24px] pb-10 sm:text-[32px] md:text-[44px] text-white">
          Industry Expertise
        </h1>
        <div className="flex relative z-20 flex-col md:flex-row justify-between pt-7">
          <div className="w-full md:w-[30%] mb-6 md:mb-0"></div>
          <div className="flex flex-wrap gap-4 sm:gap-6 w-full md:w-[60%]">
            <div
              
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <IoWalletSharp className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Finance
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <IoIosCube className="text-black text-[16px] sm:text-[18px]" />
              </div>
              E-commerce
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <HiMiniCpuChip className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Proptech
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <GiGraduateCap className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Education
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <PiHospitalFill className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Healthcare
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <FaTruckFast className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Transport & logistics
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <IoMdAirplane className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Traveling & Ticketing
            </div>
            <div
              className="py-[18px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[22px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-[18px] sm:text-[20px] text-white font-NeueMedium"
            >
              <div className="bg-white p-1 rounded-full">
                <BsTelephoneFill className="text-black text-[16px] sm:text-[18px]" />
              </div>
              Telecommunication
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute z-20  bottom-0 left-0 minimd:block hidden"
      >
        <img
          className="opacity-100 translate-y-0 transition-all duration-[1300ms] w-[100%]"
          src={isHovered === true ? Frame1 : NullFrame}
          alt="glow"
        />
      </div>
    </div>
  );
};

export default IndustryExpertise;
