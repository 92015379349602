import { useCallback, useEffect, useRef, useState } from "react";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import {
  FaArrowCircleRight,
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
  FaQuoteRight,
} from "react-icons/fa";
import phoneImage1 from "../assets/images/phone1.svg";
import phoneImage2 from "../assets/images/phone2.svg";
import phoneImage3 from "../assets/images/phone3.svg";
import phoneImage4 from "../assets/images/fourthcard.svg";
import bg1 from "../assets/images/bg1.svg";
import bg2 from "../assets/images/bg1.svg";
import bg3 from "../assets/images/bg1.svg";
import bg4 from "../assets/images/bg1.svg";
import WhyEleven36 from "../components/WhyEleven36";
import HowWeServeYou from "../components/HowWeServeYou";
import Go from "../assets/icons/go-6 1.svg";
import RN from "../assets/icons/reactnative.svg";
import Node from "../assets/icons/nodejs.svg";
import Lar from "../assets/icons/laravel.svg";
import Js from "../assets/icons/Javascript_badge.svg";
import Dart from "../assets/icons/dart.svg";
import Pyth from "../assets/icons/Python_logo_and_wordmark.svg";
import Flut from "../assets/icons/Google-flutter-logo.svg";
import Java from "../assets/icons/java.svg";
import Ang from "../assets/icons/AngularJS_logo.svg";
import My from "../assets/icons/MySQL.svg";
import Sass from "../assets/icons/sass-1.svg";
import Flag from "../assets/icons/flag.svg";
import IndustryExpertise from "../components/IndustryExpertise";
import { AiFillStar } from "react-icons/ai";
import VidBg from "../assets/images/AdobeStock_53418187.mov";
import HeroBg from "../assets/images/herobg.mp4";
import parnterOne from "../assets/images/partners/e36 partners-01.svg";
import parnterTwo from "../assets/images/partners/e36 partners-02.svg";
import parnterThree from "../assets/images/partners/e36 partners-03.svg";
import parnterFour from "../assets/images/partners/e36 partners-04.svg";
import parnterFive from "../assets/images/partners/e36 partners-05.svg";
import parnterSix from "../assets/images/partners/e36 partners-06.svg";
import parnterSeven from "../assets/images/partners/e36 partners-07.svg";
import parnterEight from "../assets/images/partners/e36 partners-08.svg";
import parnterNine from "../assets/images/partners/e36 partners-09.svg";
import parnterTen from "../assets/images/partners/e36 partners-10.svg";
import parnterEleven from "../assets/images/partners/e36 partners-11.svg";
import Logo from "../assets/images/logo.svg";
import LogoTwo from "../assets/images/logotwo.svg";
import LogoThree from "../assets/images/logothree.svg";
import { Link } from "react-router-dom";

const Homepage = () => {
  const images = [bg1, bg2, bg3, bg4];
  const phoneImages = [phoneImage2, phoneImage3, phoneImage1, phoneImage4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTestIndex, setCurrentTestIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [testItemsToShow, setTestItemsToShow] = useState(3);
  const [step, setStep] = useState(1);

  const changeStep = (direction: any) => {
    if (direction === "next") {
      setStep((prevStep) => (prevStep === 3 ? 1 : prevStep + 1));
    } else if (direction === "prev") {
      setStep((prevStep) => (prevStep === 1 ? 3 : prevStep - 1));
    }
  };

  const prevStepSlide = useCallback(() => changeStep("prev"), []);
  const nextStepSlide = useCallback(() => changeStep("next"), []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextStepSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [nextStepSlide]);
  const totalSlides = images.length;
  const slides = [
    {
      bg: images[2],
      phone: phoneImages[2],
      title: "Digital bank",
      description: "Digital banking mobile and web app",
    },
    {
      bg: images[1],
      phone: phoneImages[1],
      title: "EMR solution",
      description: "EMR mobile and web app",
    },
    {
      bg: images[0],
      phone: phoneImages[0],
      title: "Digital marketplace",
      description: "E-commerce mobile and web app",
    },
    {
      bg: images[totalSlides - 1],
      phone: phoneImages[totalSlides - 1],
      title: "Furniture marketplace",
      description: "E-commerce mobile app",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  const partnerImages = [
    {
      image: parnterOne,
    },
    {
      image: parnterTwo,
    },
    {
      image: parnterThree,
    },
    {
      image: parnterFour,
    },
    {
      image: parnterFive,
    },
    {
      image: parnterSix,
    },
    {
      image: parnterSeven,
    },
    {
      image: parnterEight,
    },
    {
      image: parnterNine,
    },
    {
      image: parnterTen,
    },
    {
      image: parnterEleven,
    },
  ];
  const totalTestSlides = partnerImages.length;
  useEffect(() => {
    const handleResize = () => {
      const itemsToShow = window.innerWidth < 768 ? 1 : 3;
      setItemsToShow(itemsToShow);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const testHandleResize = () => {
      const testItemsToShow = window.innerWidth < 768 ? 1 : 4;
      setTestItemsToShow(testItemsToShow);
    };

    window.addEventListener("resize", testHandleResize);
    testHandleResize();

    return () => {
      window.removeEventListener("resize", testHandleResize);
    };
  }, []);
  const gradients = [
    "bg-gradient-to-b from-[#528AEF] to-[#6D56F0]",
    "bg-gradient-to-b from-[#3EABA1] to-[#3EABA1]",
    "bg-gradient-to-b from-[#9CE5FF] to-[#5B98C4]",
    "bg-gradient-to-b from-[#FE7115] to-[#FE7115]",
  ];

  const nextTestSlide = () => {
    setCurrentTestIndex((prevIndex) =>
      prevIndex === totalTestSlides - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestSlide = () => {
    setCurrentTestIndex((prevIndex) =>
      prevIndex === 0 ? totalTestSlides - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    const autoNext = setInterval(() => {
      setCurrentTestIndex((prevIndex) =>
        prevIndex === totalTestSlides - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(autoNext);
  }, [totalTestSlides]);
  const [text] = useTypewriter({
    words: ["Vision", "Growth", "Success", "Guide", "Support", "Empower"],
    loop: 0,
    typeSpeed: 200,
    deleteSpeed: 200,
    delaySpeed: 1000,
  });
  const getStyledText = (word: any) => {
    switch (word) {
      case "V":
      case "Vi":
      case "Vis":
      case "Visi":
      case "Visio":
      case "Vision":
      case "G":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "Gr":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "Gro":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "Grow":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "Growt":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "Growth":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "S":
      case "Su":
      case "Suc":
      case "Succ":
      case "Succe":
      case "Succes":
      case "Success":
      case "G":
        return <span style={{ color: "#FFF500" }}>{word}</span>;
      case "Gu":
        return <span style={{ color: "#FFF500" }}>{word}</span>;
      case "Gui":
        return <span style={{ color: "#FFF500" }}>{word}</span>;
      case "Guid":
        return <span style={{ color: "#FFF500" }}>{word}</span>;
      case "Guide":
        return <span style={{ color: "#FFF500" }}>{word}</span>;
      case "S":
      case "Su":
      case "Sup":
      case "Supp":
      case "Suppo":
      case "Suppor":
      case "Support":
      case "E":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      case "Em":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      case "Emp":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      case "Empo":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      case "Empow":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      case "Empowe":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      case "Empower":
        return <span style={{ color: "#E87203" }}>{word}</span>;
      default:
        return word;
    }
  };

  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [solutionsDeveloped, setSolutionsDeveloped] = useState(0);
  const [lifetimeUsers, setLifetimeUsers] = useState(0);
  const [onDemandExperts, setOnDemandExperts] = useState(0);
  const sectionRef = useRef(null);
  const [hasCounted, setHasCounted] = useState(false);

  useEffect(() => {
    const countUp = (setter: any, target: any) => {
      let count = 0;
      const interval = setInterval(() => {
        if (count < target) {
          count++;
          setter(count);
        } else {
          clearInterval(interval);
        }
      }, 200);
    };

    const handleIntersect = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && !hasCounted) {
          setHasCounted(true); // Set counting to true
          countUp(setYearsOfExperience, 5);
          countUp(setSolutionsDeveloped, 20);
          countUp(setLifetimeUsers, 16);
          countUp(setOnDemandExperts, 40);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.1, // Trigger when 10% of the section is visible
    });

    const currentRef = sectionRef.current; // Store the current ref in a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Cleanup observer on component unmount
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [hasCounted]);

  return (
    <div className="z-50">
      <div>
        <div className="relative h-[90vh] flex items-center justify-center overflow-hidden">
          <video
            className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover z-0" // Set z-index to 0
            src={HeroBg}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70 z-10"></div>
          <div className="relative z-20 md:flex mt-6 flex-col justify-center items-center w-full px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem]">
            <h1 className="text-white md:text-center text-start font-NeueMedium w-full md:w-[80%] lg:w-[75%]  text-[30px] md:text-4xl lg:text-[56px] mb-4 lg:leading-[64px] md:leading-[48px] leading-[40px]">
              Experience custom digital innovations that drive{" "}
              {getStyledText(text)}
              <Cursor />
            </h1>
            <h2 className="text-white font-NeueMedium md:text-center w-full md:w-[80%] lg:w-[60%] text-sm md:text-base lg:text-[20px] lg:leading-[32px] md:leading-[28px] leading-[20px]">
              At Eleven36, we give you a take in the future and dividends of
              technology through access to on-demand software development,
              design, ideation, and tech consultancy services.
            </h2>
            {/* <button className="py-3 flex items-center gap-2 px-6 border-2 border-white rounded-full text-sm md:text-base lg:text-lg text-white font-NeueMedium mt-8">
              Book a free consultation
              <FaArrowCircleRight className="text-white text-lg" />
            </button> */}
            <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
              Book a free consultation{" "}
              <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
              <span></span>
            </Link>
          </div>
        </div>
        <div ref={sectionRef}>
          <div className="relative z-20 bg-gradient-to-b from-black to-[#081412] pt-[100px] pb-[70px]">
            <div className="mx-auto max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem] flex-col sm:flex-row items-center justify-between flex">
              <div className="mb-6 sm:mb-0 text-center">
                <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[65px] font-NeueMedium">
                  {yearsOfExperience}+
                </h1>
                <h1 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
                  Years of Experience
                </h1>
              </div>
              <div className="mb-6 sm:mb-0 text-center">
                <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[65px] font-NeueMedium">
                  {solutionsDeveloped}+
                </h1>
                <h1 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
                  Solutions developed
                </h1>
              </div>
              <div className="mb-6 sm:mb-0 text-center">
                <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[65px] font-NeueMedium">
                  {`${lifetimeUsers}M+`}
                </h1>
                <h1 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
                  Life time end users
                </h1>
              </div>
              <div className="text-center">
                <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[65px] font-NeueMedium">
                  {onDemandExperts}+
                </h1>
                <h1 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
                  On-demand experts
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-wrapper px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem] relative mt-[100px]">
          <div className="flex items-center justify-between">
            <h1 className="text-white md:text-[40px] text-[24px] font-NeueMedium">
              Featured work
            </h1>
            <div className="flex items-center gap-2">
              <button
                className="text-[20px] p-[14px] bg-[#1C1B1B] rounded-full hover:bg-[#45C4B0] flex items-center justify-center text-white text-4xl"
                onClick={prevSlide}
              >
                <FaArrowLeft />
              </button>
              <button
                className="text-[20px] p-[14px] bg-[#1C1B1B] rounded-full hover:bg-[#45C4B0] flex items-center justify-center text-white text-4xl"
                onClick={nextSlide}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>

          <div className="carousel relative overflow-hidden w-full">
            <div
              className="carousel-container flex transition-transform ease-in-out duration-500 space-x-4" // Added space-x-4 for spacing between cards
              style={{
                transform: `translateX(-${
                  (currentIndex * 100) / itemsToShow
                }%)`,
              }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`card-container flex-shrink-0 w-full md:w-[40%] flex flex-col`}
                >
                  {/* Card Image */}
                  <div
                    className={`card relative h-[500px] sm:h-[400px] md:h-[60vh] w-full rounded-[24px] ${
                      gradients[index % gradients.length]
                    } overflow-hidden flex flex-col`}
                  >
                    <img
                      src={slide.bg}
                      alt={`bg-${index}`}
                      className="absolute w-full h-full object-cover"
                    />
                    <img
                      src={slide.phone}
                      alt={`phone-${index}`}
                      className="phone-image absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-40 md:h-60 lg:h-80 object-contain transition-transform duration-500 hover:scale-110"
                    />
                  </div>

                  {/* Text Outside of Card */}
                  <div className="mt-4 text-left">
                    {" "}
                    {/* Changed to text-left for left alignment */}
                    <h3 className="text-[18px] font-NeueMedium md:text-[22px] text-white">
                      {slide.title}
                    </h3>
                    <p className="text-[14px] font-NeueRegular md:text-[16px] text-[#A9A9A9]">
                      {slide.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <HowWeServeYou />

        <div className="px-[1rem] minimd:px-[1.5rem] md:px-[2rem] lg:px-[3rem] max-w-[87.5rem] relative mx-auto pt-[80px] minimd:pt-[90px] md:pt-[100px]">
          <div>
            <div className="flex items-center justify-between">
              <h1 className="text-white text-[30px] minimd:text-[34px] md:text-[36px] lg:text-[44px] pb-3 font-NeueMedium">
                Start your transformative journey in{" "}
                <span className="text-[#45C4B0]">3 easy steps</span>
              </h1>
              <div className="flex items-center gap-2">
                <button
                  className="text-[20px] p-[14px] bg-[#1C1B1B] rounded-full hover:bg-[#45C4B0] flex items-center justify-center text-white"
                  onClick={prevStepSlide}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className="text-[20px] p-[14px] bg-[#1C1B1B] rounded-full hover:bg-[#45C4B0] flex items-center justify-center text-white"
                  onClick={nextStepSlide}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
            <div className="flex flex-col minimd:flex-row justify-between items-start pt-8">
              {step === 1 && (
                <div className="w-full minimd:w-[40%] lg:w-[33.3%]">
                  <h1 className="text-[#45C4B0] cursor-pointer pb-2 text-[22px] minimd:text-[24px] font-NeueMedium">
                    <span>01</span> Book a Free Consultation
                  </h1>
                  <h1 className="text-[#434343] cursor-pointer pb-2 text-[22px] minimd:text-[24px] font-NeueMedium">
                    <span>02</span> Get a Comprehensive Analysis
                  </h1>
                  <h1 className="text-[#181818] cursor-pointer pb-2 text-[22px] minimd:text-[24px] font-NeueMedium">
                    <span>03</span> Get a Market-ready Solution
                  </h1>
                </div>
              )}
              {step === 2 && (
                <div className="w-full minimd:w-[40%] lg:w-[33.3%]">
                  <h1 className="text-[#45C4B0] cursor-pointer pb-2 text-[22px] minimd:text-[24px] font-NeueMedium">
                    <span>02</span> Get a Comprehensive Analysis
                  </h1>
                  <h1 className="text-[#434343] cursor-pointer pb-2 text-[22px] minimd:text-[24px] font-NeueMedium">
                    <span>03</span> Get a Market-ready Solution
                  </h1>
                </div>
              )}
              {step === 3 && (
                <div className="w-full minimd:w-[40%] lg:w-[33.3%]">
                  <h1 className="text-[#45C4B0] cursor-pointer pb-2 text-[22px] minimd:text-[24px] font-NeueMedium">
                    <span>03</span> Get a Market-ready Solution
                  </h1>
                </div>
              )}
              <div className="mt-6 minimd:mt-0">
                <img
                  key={step}
                  src={step === 1 ? Logo : step === 2 ? LogoTwo : LogoThree}
                  className="w-[80%] minimd:w-[90%] transition-opacity duration-1000 ease-in-out opacity-0"
                  onLoad={(e) =>
                    (e.target as HTMLImageElement).classList.add("opacity-100")
                  }
                  alt="logo"
                />
              </div>
              {step === 1 ? (
                <div className="w-full minimd:w-[40%] lg:w-[33.3%] mt-6 minimd:mt-0">
                  <h1 className="text-[18px] md:text-[22px] minimd:text-[24px] font-NeueRegular pb-3 text-white">
                    Unsure what you need?
                  </h1>
                  <h1 className="text-[18px] md:text-[22px] minimd:text-[24px] font-NeueRegular pb-3 text-white">
                    Unsure about why you should work with us?
                  </h1>
                  <h1 className="text-[18px] md:text-[22px] minimd:text-[24px] font-NeueRegular pb-3 text-white">
                    Let’s get to know each other and see how we match.
                  </h1>
                  {/* <button className="py-2 flex items-center gap-2 px-4 border-2 border-white rounded-full text-sm minimd:text-base lg:text-lg text-white font-NeueMedium mt-5">
                    Book a free consultation
                    <FaArrowCircleRight className="text-white text-lg" />
                  </button> */}
                  <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
                    Book a free consultation{" "}
                    <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
                    <span></span>
                  </Link>
                </div>
              ) : step === 2 ? (
                <div className="w-full minimd:w-[40%] lg:w-[33.3%] mt-6 minimd:mt-0">
                  <h1 className="text-[18px] md:text-[22px] minimd:text-[24px] font-NeueRegular pb-3 text-white">
                    We do a Comprehensive analysis of your idea, existing
                    business, or potential idea to provide you with what, who
                    and how your success can be achieved.
                  </h1>
                  {/* <button className="py-2 flex items-center gap-2 px-4 border-2 border-white rounded-full text-sm minimd:text-base lg:text-lg text-white font-NeueMedium mt-5">
                    Book a free consultation
                    <FaArrowCircleRight className="text-white text-lg" />
                  </button> */}
                  <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
                    Book a free consultation{" "}
                    <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
                    <span></span>
                  </Link>
                </div>
              ) : (
                <div className="w-full minimd:w-[40%] lg:w-[33.3%] mt-6 minimd:mt-0">
                  <h1 className="text-[18px] md:text-[22px] minimd:text-[24px] font-NeueRegular pb-3 text-white">
                    Our industry experts work with you to ideate, design and
                    develop your custom solution in the shortest possible time.
                  </h1>
                  {/* <button className="py-2 flex items-center gap-2 px-4 border-2 border-white rounded-full text-sm minimd:text-base lg:text-lg text-white font-NeueMedium mt-5">
                    Book a free consultation
                    <FaArrowCircleRight className="text-white text-lg" />
                  </button> */}
                  <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
                    Book a free consultation{" "}
                    <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
                    <span></span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="relative mt-[80px] md:mt-[120px] pt-[60px] md:pt-[100px]">
          <div className="absolute inset-0 w-full h-full radial-gradient-bg1 opacity-40 z-0"></div>
          <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>

          {/* Content */}
          <div className="relative z-20 px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem] md:flex block justify-between">
            {/* Left Section - Text */}
            <div className="md:w-[40%]">
              <h1 className="text-[30px] md:text-[36px] lg:text-[44px] pb-3 font-NeueMedium text-white">
                Our tech stack
              </h1>
              <h1 className="text-[16px] md:text-[18px] lg:text-[22px] text-[#A9A9A9] pb-3 font-NeueRegular">
                We pride ourselves on leveraging a cutting-edge technology stack
                that ensures efficiency, scalability, and innovation in every
                project we undertake.
              </h1>
              <h1 className="text-[16px] md:text-[18px] lg:text-[22px] text-[#A9A9A9] font-NeueRegular">
                Our diverse and robust tech stack empowers us to deliver
                exceptional software solutions tailored to meet the unique needs
                of our clients.
              </h1>
            </div>

            {/* Right Section - Tech Stack */}
            <div className="md:w-[53%] sm:pb-[100px] pb-[80px]">
              <div className="flex items-center space-x-3 py-2 md:py-3">
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Go}
                    alt="go"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Node}
                    alt="nodejs"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={RN}
                    alt="reactnative"
                    className="w-[40px] md:w-[50px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-3 py-2 md:py-3">
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Lar}
                    alt="laravel"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Js}
                    alt="javascript"
                    className="w-[40px] md:w-[50px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Pyth}
                    alt="python"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-3 py-2 md:py-3">
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Flut}
                    alt="flutter"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Java}
                    alt="java"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Ang}
                    alt="angular"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
              </div>

              {/* Fourth Row */}
              <div className="flex items-center space-x-3 py-2 md:py-3">
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={My}
                    alt="mysql"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Sass}
                    alt="sass"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
                <div className="w-[120px] h-[80px] md:w-[180px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                    src={Dart}
                    alt="dart"
                    className="w-[80px] md:w-[100px] transition-all duration-300 group-hover:filter group-hover:invert"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <WhyEleven36 />
        <IndustryExpertise />

        <div className="carousel-wrapper max-w-[87.5rem] px-[1rem] md:px-[2rem] lg:px-[3rem] relative mx-auto py-[100px]">
          <div className="md:flex items-center justify-between">
            <h1 className="text-white md:text-[40px] text-[24px] font-NeueMedium">
              Our Partners
            </h1>
          </div>
          <div className="relative overflow-hidden w-full mt-8 pt-16">
            <div
              className="grid grid-cols-4 gap-16"
              // style={{
              //   transform: `translateX(-${
              //     (currentTestIndex * 100) / testItemsToShow
              //   }%)`,
              // }}
            >
              {partnerImages.map((partner, index) => (
                <div key={index} className="h-[80px] md:h-[100px] bg-white hover:border border-white border-opacity-20 backdrop-blur-md hover:bg-opacity-20 cursor-pointer bg-opacity-5 rounded-[12px] md:rounded-[16px] flex justify-center items-center group">
                  <img
                          src={partner.image}
                          className="h-[60px] object-cover"
                          alt="testimonial"
                        />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative sm:mt-[140px] py-[60px] md:py-[100px]">
          <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
          <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
          <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] mb-[40px] md:mb-[60px]">
            <div className="relative z-20 w-full overflow-hidden rounded-[16px] md:rounded-[24px]">
              <video
                className="h-[200px] md:h-[430px] w-full object-cover"
                src={VidBg}
                autoPlay
                loop
                muted
              />
            </div>
          </div>

          <div className="relative z-20 max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] flex flex-col lg:flex-row justify-between">
            <div className="w-full lg:w-[70%] mb-4 lg:mb-0">
              <h1 className="text-[28px] md:text-[36px] lg:text-[44px] pb-3 font-NeueMedium text-white">
                We are Eleven36
              </h1>
              <h1 className="text-[16px] md:text-[18px] lg:text-[22px] text-[#A9A9A9] pb-3 font-NeueRegular">
                At Eleven36, we're a diverse team of strategic disruptors with
                the core belief that technology should be adapted to suit every
                aspect, contour, and phase of individual and organisational
                needs.
              </h1>
              <h1 className="text-[16px] md:text-[18px] lg:text-[22px] text-[#A9A9A9] font-NeueRegular">
                We blend aspirational business goals, in-depth user needs, and
                digital innovation, to craft unique solutions that drive
                transformation and lead the charge into the future.
              </h1>
            </div>
            <div>
              <button className="py-[10px] hover:bg-[#45C4B0] hover:border-[#45C4B0] hover:text-black md:py-[12px] flex items-center gap-2 px-[12px] md:px-[16px] border-[1.5px] border-white rounded-[40px] md:rounded-[60px] text-white font-NeueMedium">
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default Homepage;
