import { FaArrowCircleRight, FaArrowUp } from "react-icons/fa";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import phoneImage1 from "../assets/images/port1.svg";
import phoneImage2 from "../assets/images/port2.svg";
import phoneImage3 from "../assets/images/mart-phone.svg";
import bg from "../assets/images/bg-pattern.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HeroImg from "../assets/images/porthro.png";
// import HeroImg from "../assets/images/GroupPattern.svg";
const Portfolio = () => {
  const [text] = useTypewriter({
    words: ["Guide", "Support", "Empower"],
    loop: 0,
    typeSpeed: 200,
    deleteSpeed: 200,
    delaySpeed: 1000,
  });
  const getStyledText = (word: any) => {
    switch (word) {
      case "Guide":
        return <span style={{ color: "#45C4B0" }}>{word}</span>;
      case "Support":
        return <span style={{ color: "#FFDA2C" }}>{word}</span>;
      case "Empower":
        return <span style={{ color: "#FF7B00" }}>{word}</span>;
      default:
        return word;
    }
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="z-50">
      <div className="relative h-[90vh] overflow-hidden">
        <img
          src={HeroImg}
          className="absolute top-0 left-[23.5%] bottom-0 w-full h-full object-contain z-30"
          alt=""
        />
        <div className="absolute inset-0 w-full top-0 left-0 right-0 bottom-0 h-full radial-gradient-bg3 bg-opacity-5 z-10"></div>

        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-60 z-20"></div>
        <div className="max-w-[87.5rem] mx-auto px-[1rem] md:px-[2rem] lg:px-[3rem] mb-[60px]"></div>
        <div className="relative z-30 max-w-[87.5rem] mt-[-30px] w-full flex flex-col justify-center mx-auto px-[1rem] h-full md:px-[2rem] lg:px-[3rem]">
          <div>
            <h1 className="text-white font-NeueMedium w-[70%] lg:w-[60%] text-[30px] md:text-4xl lg:text-[56px] mb-4 lg:leading-[64px] md:leading-[48px] leading-[40px]">
              We’ve been there,
            </h1>
            <h1 className="text-white font-NeueMedium w-[70%] lg:w-[60%]  text-[30px] md:text-4xl lg:text-[56px] mb-4 lg:leading-[64px] md:leading-[48px] leading-[40px]">
              We can {getStyledText(text)}
              <Cursor /> you.
            </h1>
            <h1 className="text-white font-NeueMedium w-[70%] lg:w-[60%] text-sm md:text-base lg:text-[20px] lg:leading-[32px] md:leading-[28px] leading-[20px]">
              Explore our past projects, let our successes inspire you to
              imagine what we can create together.
            </h1>
            <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
              Book a free consultation{" "}
              <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
              <span></span>
            </Link>
          </div>
        </div>
      </div>

      <div className="md:py-[100px] py-[80px] lg:px-[3rem] md:px-[2rem] px-[1rem] mx-auto max-w-[87.5rem]">
        <h1 className="text-white md:text-[40px] sm:pb-[60px] pb-[30px] text-[24px] font-NeueMedium">
          Featured projects
        </h1>
        <Link to="/digital-banking-solution" className="relative z-20">
          <div className="bg-gradient-to-b overflow-hidden relative rounded-[24px] from-[#528AEF] flex justify-between to-[#6D56F0] group">
            <div className="md:w-[50%] w-full lg:p-[72px] md:p-[50px] p-[30px] relative z-10">
              <h1 className="md:text-[30px] sm:text-[26px] text-[24px] md:pb-5 sm:pb-3 pb-1.5 font-NeueBold text-white">
                Digital bank
              </h1>
              <p className="md:text-[18px] sm:text-[16px] text-[14px] font-NeueRegular text-white">
                A cutting-edge digital banking platform designed for optimal
                financial management, enabling secure transactions, advanced
                account monitoring, personalized banking experiences, and
                integrated wealth management tools.
              </p>
              <div className="bg-[#0E0E0E40] md:mt-10 sm:mt-6 mt-4 bg-opacity-20 inline-block xmd:text-[15px] sm:text-[13px] text-[11px] font-NeueRegular text-white md:py-2 py-1 px-3 rounded-[60px]">
                Digital Banking mobile & web app
              </div>
            </div>
            <div className="w-[50%] hidden md:flex items-end justify-end relative z-10">
              {" "}
              {/* Added z-10 */}
              <img
                src={phoneImage1}
                alt="phone"
                className="port-phone-image absolute top-1/2 bottom-0 right-1/2 transform -translate-x-1/2 -translate-y-1/2 h-40 md:h-60 lg:h-[1000px] object-contain transition-transform duration-500 group-hover:scale-110"
              />
            </div>

            {/* Background Image */}
            <div
              className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-0" // Set z-index to 0
              style={{ backgroundImage: `url(${bg})` }}
            ></div>
          </div>
        </Link>

        <Link to="/electronic-medical-records" className="relative z-20">
          <div className="bg-[#2a766f] lg:p-[72px] md:p-[50px] p-[30px] overflow-hidden relative z-10 flex justify-between md:mt-10 sm:mt-6 mt-3 rounded-[24px] group">
            <div className="w-[50%] md:flex hidden justify-end relative">
              <img
                src={phoneImage2}
                alt="phone"
                className="port-phone-image absolute top-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 h-40 md:h-60 lg:h-[400px] object-contain transition-transform duration-500 group-hover:scale-110"
              />
            </div>
            <div className="md:w-[50%] w-full">
              <h1 className="md:text-[30px] sm:text-[26px] text-[24px] md:pb-5 sm:pb-3 pb-1.5 font-NeueBold text-white">
                Electronic Medical Records Solution
              </h1>
              <p className="md:text-[18px] sm:text-[16px] text-[14px] font-NeueRegular text-white">
                A deluxe EMR system designed for optimal healthcare management,
                enabling secure access to patient data, advanced monitoring,
                personalized care plans, and integrated health management tools.
              </p>
              <div className="bg-[#0E0E0E40] md:mt-10 sm:mt-6 mt-4 bg-opacity-20 inline-block xmd:text-[15px] sm:text-[13px] text-[11px] font-NeueRegular text-white md:py-2 py-1 px-3 rounded-[60px]">
                EMR Mobile & Web app
              </div>
            </div>
            <div
              className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-0" // Set z-index to 0
              style={{ backgroundImage: `url(${bg})` }}
            ></div>
          </div>
        </Link>
        <Link to="/digital-marketplace" className="relative z-20">
          <div className="bg-gradient-to-b overflow-hidden relative md:mt-10 sm:mt-6 z-10 mt-3 rounded-[24px] flex justify-between from-[#97E9FF] to-[#5194C7] group">
            <div className="md:w-[50%] w-full lg:p-[72px] md:p-[50px] p-[30px] relative z-10">
              {" "}
              {/* Added relative and z-10 */}
              <h1 className="md:text-[30px] sm:text-[26px] text-[24px] md:pb-5 sm:pb-3 pb-1.5 font-NeueBold text-white">
                Digital marketplace
              </h1>
              <p className="md:text-[18px] sm:text-[16px] text-[14px] font-NeueRegular text-white">
                A premium eCommerce platform built for online buying & selling,
                seamless payments, robust inventory management, customizable
                storefronts, and comprehensive analytics.
              </p>
              <div className="bg-[#0E0E0E40] md:mt-10 sm:mt-6 mt-4 bg-opacity-20 inline-block xmd:text-[15px] sm:text-[13px] text-[11px] font-NeueRegular text-white md:py-2 py-1 px-3 rounded-[60px]">
                E-commerce mobile & web app
              </div>
            </div>
            <div className="w-[50%] hidden md:flex items-end justify-end relative z-10">
              {" "}
              {/* Added z-10 */}
              <img
                src={phoneImage3}
                alt="phone"
                className="port-phone-image absolute top-1/2 bottom-0 right-1/2 transform -translate-x-1/2 -translate-y-1/2 h-40 md:h-60 lg:h-[1000px] object-contain transition-transform duration-500 group-hover:scale-110"
              />
            </div>
            <div
              className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-0" // Set z-index to 0
              style={{ backgroundImage: `url(${bg})` }}
            ></div>
          </div>
        </Link>
      </div>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default Portfolio;
