import {
  FaArrowUp,
  FaEnvelope,
  FaMoneyBill,
  FaMoneyCheck,
  FaUser,
} from "react-icons/fa";
import Phone1 from "../assets/images/digitalbankphone.svg";
import Phone2 from "../assets/images/Galaxy Note 20 Ultra.svg";
import Ars from "../assets/images/Astronaut with space shuttle 1.gif";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosCard, IoMdCheckmark } from "react-icons/io";
import { GiWallet } from "react-icons/gi";
import App from "../assets/images/app.svg";
import Db from "../assets/images/db.svg";
import Reactlogo from "../assets/images/react.svg";
import Phone from "../assets/images/solution-phone1.svg";
import Logo from "../assets/images/Group (1).svg";
import { useEffect, useState } from "react";
import HeroImg from "../assets/images/bankhero.png";
// import HeroImg from "../assets/images/GroupPattern.svg";
const DigitalBanking = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="z-50">
      <div className="relative py-[100px] sm:h-[95vh] h-[88vh] overflow-hidden">
        <img
          src={HeroImg}
          className="absolute top-0 left-[20%] bottom-0 w-full h-full object-contain z-20"
          alt=""
        />
        {/* <img
          src={HeroImg}
          className="absolute top-0 left-[40%] bottom-0 w-full h-full object-cover z-20 container"
          alt=""
        /> */}
        <div className="absolute inset-0 w-full h-full opacity-40 radial-gradient-bg4 z-10"></div>

        <div className="max-w-[87.5rem] mx-auto px-4 md:px-[3rem] lg:px-[5rem]"></div>

        <div className="relative z-20 max-w-[87.5rem] mx-auto px-4 md:pt-0 pt-[100px] md:px-[3rem] lg:px-[5rem] h-full">
          <div className="w-full flex flex-col md:flex-row justify-between h-full">
            {/* Left Section */}
            <div className="w-full md:w-[55%] flex flex-col justify-center text-start md:text-left">
              <h1 className="text-white font-NeueMedium w-full text-[30px] md:text-4xl lg:text-5xl">
                Digital banking solution
              </h1>
              <h1 className="font-NeueMedium text-[20px] md:text-[22px] text-white mt-6 md:mt-8">
                Services:
              </h1>
              <h1 className="text-white font-NeueRegular w-full md:w-[80%] lg:w-[60%] text-sm md:text-base lg:text-lg mx-auto md:mx-0">
                Discovery phase, UI/UX design, Web & Mobile app development
              </h1>
            </div>

            {/* Right Section (Image) */}
            <div className="w-full md:w-[45%] h-full md:flex hidden items-center mt-8 md:mt-0">
              <img
                src={Phone1}
                alt="Digital banking phone"
                className="w-[80%] md:w-full h-auto object-contain mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-white pt-[100px] mx-auto max-w-[87.5rem] px-4 md:px-[3rem] lg:px-[5rem]">
        <div className="flex">
          <div className="border-r border-[#FFFFFF52] inline-block pr-[50px]">
            <h1 className="font-NeueMedium text-[22px]">Industry:</h1>
            <h2 className="text-[15px] font-NeueRegular text-[#E7E7E7]">
              Finance
            </h2>
          </div>
          <div className="pl-[50px] inline-block">
            <h1 className="font-NeueMedium text-[22px]">Team:</h1>
            <h2 className="text-[15px] font-NeueRegular text-[#E7E7E7]">
              9 Specialists
            </h2>
          </div>
        </div>
        <div className="inline-block pt-7">
          <h1 className="font-NeueMedium md:text-[22px] sm:text-[20px] text-[18px]">
            Project description:
          </h1>
          <h2 className="sm:text-[15px] text-[13px] font-NeueRegular sm:w-[60%] w-full text-[#E7E7E7]">
            A state-of-the-art digital banking platform designed for secure
            e-transactions, account management, personalized financial insights,
            and more.
          </h2>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between sm:gap-[90px] gap-[45px] sm:text-start text-center pb-6 pt-[60px]">
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              4 months
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              For Full-feature App
            </h2>
          </div>
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              1300+
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              Transactions in 1st week of Launch
            </h2>
          </div>
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              4.9
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              Combined store rating
            </h2>
          </div>
        </div>
      </div>

      <div className="py-[80px] sm:py-[100px] px-4 md:px-[3rem] lg:px-[5rem] mx-auto max-w-[87.5rem]">
        <h1 className="font-NeueMedium md:pb-0 pb-6 text-[28px] sm:text-[36px] md:text-[44px] text-white">
          About this project
        </h1>
        <div className="md:flex block items-start justify-between">
          <div className="w-[40%]"></div>
          <div className="md:w-[60%]">
            <div className="pb-7 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#45C4B0]">
                01
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The partner
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  The partner is a reputable Microfinance Bank (MFB),
                  distinguished for its extensive portfolio of financial
                  services accessible through online platforms. With a wide
                  range of offerings, they cater to diverse financial needs and
                  preferences.
                </p>
              </div>
            </div>

            <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C]">
                02
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The big picture
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  The primary goal of this project is to develop a robust online
                  platform that not only enhances the bank's online presence but
                  also improves customer engagement, operational efficiency, and
                  drives business growth.
                </p>
              </div>
            </div>

            <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00]">
                03
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The partner’s objectives
                </h1>
                <div className="pb-3 pt-4">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[25px] font-NeueMedium text-white">
                    Expand Online Presence:
                    <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                      Enhance the Microfinance Bank's visibility and
                      accessibility by establishing a robust online platform to
                      provide comprehensive financial services.
                    </p>
                  </h1>
                </div>
                <div className="pb-3 ">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[25px] font-NeueMedium text-white">
                    Improve Customer Engagement:
                    <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                      Foster stronger connections with customers by offering
                      user-friendly digital banking solutions that cater to
                      their diverse needs and preferences.
                    </p>
                  </h1>
                </div>
                <div className="pb-3 ">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[25px] font-NeueMedium text-white">
                    Enhance Operational Efficiency:
                    <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                      Streamline banking processes and transactions through an
                      efficient online platform that saves time and reduces
                      overhead costs.
                    </p>
                  </h1>
                </div>
                <div className="pb-3 ">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[25px] font-NeueMedium text-white">
                    Drive Business Growth:
                    <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                      Utilize the digital channel to attract new customers,
                      retain existing ones, and increase revenue streams,
                      thereby fostering sustainable business growth for the
                      Microfinance Bank.
                    </p>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[87.5rem] mx-auto px-4 md:px-[3rem] lg:px-[5rem] py-[100px]">
        <div>
          <h1 className="text-[44px] pb-6 font-NeueMedium text-white">
            Our process
          </h1>
          <div>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#45C4B0] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#45C4B0] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>1</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] font-NeueMedium group-hover:hidden text-white transform transition-all duration-300">
                      Ideation
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      The product and manager studied the project requirements,
                      developed a shared vision of the project, prepared a
                      backlog, setup tools, a project environment, and a
                      database.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#FFF500] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#FFF500] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>2</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] whitespace-nowrap font-NeueMedium text-white group-hover:hidden transform transition-all duration-300">
                      Strategic analysis
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      Our product and business analysts studied the project
                      requirements and provided software requirements and
                      specifications. Created high-level technical
                      specifications based on partner's requirements and
                      business objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4 mt-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#FD8717] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#FD8717] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>3</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] font-NeueMedium whitespace-nowrap group-hover:hidden text-white transform transition-all duration-300">
                      Technical development
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      The Software engineers studied project requirements,
                      planned and described the architecture of the mobile and
                      web app.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#C7FFED] flex items-center gap-4 sm:gap-5 p-4 sm:p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[180px] sm:h-[200px]">
                <div className="flex gap-4 sm:gap-5 items-center">
                  <div className="text-white group-hover:text-[#C7FFED] text-[16px] sm:text-[20px] font-NeueMedium rounded-full bg-black h-[36px] sm:h-[40px] w-[36px] sm:w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>4</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[16px] sm:text-[20px] font-NeueMedium group-hover:hidden text-white transform transition-all duration-300">
                      Design
                    </h1>
                    <p className="text-white text-[14px] sm:text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      Our Designer created an e-commerce mobile app design
                      concept based on the project requirements, brand book, and
                      partner's comment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#033A5499] py-[80px] md:py-[100px]">
        <div className="px-[1rem] md:px-[5rem] mx-auto flex flex-col md:flex-row items-center max-w-[87.5rem]">
          <div className="w-full md:w-[50%] mb-5 md:mb-0">
            <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
              Project mission
            </h1>
            <div className="pb-5">
              <p className="text-[#C7C7C7] md:text-[18px] text-[16px] font-NeueRegular">
                To collaborate closely with our esteemed Microfinance Bank (MFB)
                partner, leveraging our technical expertise to transform their
                vision into reality. We set out to:
              </p>
            </div>
            <ul className="list-disc pl-5">
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Iterate a Comprehensive Project Roadmap:{" "}
                <span className="text-[#C7C7C7] font-NeueRegular">
                  We explored the partner’s concept, distilling it into detailed
                  business requirements and technical specifications.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Create a User-Centric Interface Design:{" "}
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Our team crafted intuitive and visually appealing interfaces
                  tailored to each user type - customers, MFB representatives,
                  and administrators.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Develop a Customer Digital Banking Solution:{" "}
                <span className="text-[#C7C7C7] font-NeueRegular">
                  We developed a comprehensive digital banking solution tailored
                  to the unique needs of the Microfinance Bank and its
                  customers.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Develop Admin Panels:{" "}
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Our team created comprehensive admin panels equipped with
                  powerful tools to manage customer transactions, monitor
                  performance, and streamline operations.
                </span>
              </li>
              <li className="font-NeueBold md:text-[18px] text-[16px] text-white pb-5">
                Integrate with essential services:{" "}
                <span className="text-[#C7C7C7] font-NeueRegular">
                  We made all necessary integrations of the digital banking
                  solution with secure payment gateways, email services, and SMS
                  notification systems.
                </span>
              </li>
            </ul>
          </div>
          <div className="w-full hidden md:w-[50%] md:flex justify-center">
            <img
              src={Phone2}
              alt="phone"
              className="w-full max-w-[300px] md:max-w-none"
            />
          </div>
        </div>
      </div>

      <div className="relative md:py-[100px] py-[80px] ">
        <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="max-w-[87.5rem] mx-auto lg:px-[5rem] md:px-[3rem] px-4">
          <h1 className="text-white relative z-20 text-center md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            The Solution
          </h1>
          <div className="flex justify-end items-center z-20 relative pb-[80px] pt-9">
            <img
              className="absolute left-[-80px] hidden md:block w-[70%] z-10"
              src={Phone}
              alt="phone"
            />
            <div className="bg-[#1C1C1C] md:w-[55%] w-full rounded-[20px] p-[25px] md:ml-[25%] z-20 relative">
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                We developed a comprehensive digital banking solution comprising
                a user-friendly mobile app for customers and an intuitive web
                platform for bank administrators, underpinned by a robust
                administrative panel.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                Each component is intricately interconnected, ensuring smooth
                operations and an enhanced user experience.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                The core banking system securely manages transactions and
                customer data while ensuring seamless integration with external
                financial systems and compliance with banking regulations.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                This ensures data integrity and security across all platforms,
                facilitating reliable and consistent banking services.
              </h1>
            </div>
          </div>
          <hr className="text-[#FFFFFF29] w-full" />
        </div>

        <div className="relative z-20 max-w-[87.5rem] pt-[50px] mx-auto px-4 sm:px-8 md:px-[5rem]">
          <h1 className="text-white text-[24px] md:text-[32px] lg:text-[40px] pb-5 font-NeueMedium">
            Strategic advantages
          </h1>
          <div className="flex flex-col md:flex-row justify-between gap-6 md:gap-4">
            <div className="w-full md:w-1/2">
              <h1 className="text-[#45C4B0] pb-3 text-[20px] md:text-[24px] font-NeueBold">
                Scalable
              </h1>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] pb-3 font-NeueRegular">
                To enhance the security of our digital banking solution, we
                implemented multi-layered security measures including biometric
                verification and end-to-end encryption.
              </p>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] font-NeueRegular">
                The system adheres to the highest standards of data protection,
                ensuring that all customer information remains secure against
                unauthorized access and cyber threats, thereby fostering a
                secure banking environment.
              </p>
            </div>

            <div className="w-full md:w-1/2">
              <h1 className="text-[#45C4B0] pb-3 text-[20px] md:text-[24px] font-NeueBold">
                Compliance and Regulation Adherence
              </h1>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] pb-3 font-NeueRegular">
                The digital banking solution was built to comply with the most
                stringent financial regulations, including KYC (Know Your
                Customer) and AML (Anti-Money Laundering) standards.
              </p>
              <p className="text-[16px] md:text-[18px] text-[#C7C7C7] font-NeueRegular">
                We implemented systems that ensured controlled monitoring and
                adherence to the partner’s regulations, maintaining not only
                compliance but also high standards of operational transparency
                and accountability.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[50px] md:py-[100px]">
        <div className="lg:px-[5rem] md:px-[3rem] px-4 mx-auto max-w-[87.5rem]">
          <h1 className="text-white md:text-[40px] text-[24px] pb-5 font-NeueMedium text-left">
            Platform essential
          </h1>
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="bg-[#45C4B0] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Security
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                Advanced security features, including multi-factor
                authentication and encryption protocols, ensures the safety and
                privacy of customer data and transactions.
              </p>
            </div>
            <div className="bg-[#FFDA2C] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Loans
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                Customers can apply for loans directly through the app, with a
                streamlined application process and quick approval turnaround
                times.
              </p>
            </div>
            <div className="bg-[#FF7B00] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Savings
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                Users have access to various savings options, including
                traditional savings accounts, fixed deposits & investment
                opportunities, managed from their mobile device.
              </p>
            </div>
            <div className="bg-[#C7FFED] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-[#0E0E0E] pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Bulk transfer
              </h1>
              <p className="text-[#0E0E0E] text-[14px] md:text-[18px]">
                Business customers can initiate bulk transfers to multiple
                accounts simultaneously, streamlining payroll processing and
                supplier payments.
              </p>
            </div>
            <div className="bg-[#003551] p-[20px] md:p-[25px] rounded-[20px]">
              <h1 className="text-white pb-2 text-[20px] md:text-[25px] font-NeueBold">
                Airtime & Bills
              </h1>
              <p className="text-white text-[14px] md:text-[18px]">
                The app allows users to purchase airtime and pay bills for
                utilities, telecommunications, and other services with just a
                few taps.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative py-[50px] md:py-[100px]">
        <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="mx-auto relative z-20 max-w-[87.5rem] px-4 sm:px-8 md:px-[5rem]">
          <h1 className="text-white text-[24px] md:text-[32px] lg:text-[40px] pb-5 font-NeueMedium text-left">
            User adaptations
          </h1>
          <div className="space-y-10 md:space-y-0 md:space-x-0">
            <div className="pb-5 border-b border-[#FFFFFF29] space-y-10 md:space-y-0 md:flex md:justify-between">
              <div className="flex space-x-4 md:space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#45C4B0]">
                  01
                </h1>
                <div className="mt-5">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[24px] font-NeueMedium text-[#45C4B0]">
                    For customers
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[14px] sm:text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Account Registration/Login</li>
                    <li>Account Balance Management</li>
                    <li>Account security set up</li>
                    <li>Funds transactions</li>
                    <li>Transaction History</li>
                    <li>Loan Applications</li>
                    <li>Savings Management</li>
                    <li>Bill Payments</li>
                    <li>Setting Alerts</li>
                    <li>Customer Support</li>
                  </ul>
                </div>
              </div>
              <div className="flex space-x-4 md:space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C]">
                  02
                </h1>
                <div className="mt-5">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[24px] font-NeueMedium text-[#FFDA2C]">
                    For bank employees
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[14px] sm:text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Employee Registration/Login</li>
                    <li>Account security set up</li>
                    <li>Customer Account Management</li>
                    <li>Transaction Oversight</li>
                    <li>Loan Approval Processes</li>
                    <li>Savings Advisory</li>
                    <li>Regulatory Compliance</li>
                    <li>Customer Support</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-end relative mt-10 md:mt-0">
              <div className="absolute left-0 bottom-0 hidden xl:block">
                <img src={Logo} className="w-[60%] lg:w-[80%]" alt="logo" />
              </div>
              <div className="flex space-x-4 md:space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00]">
                  03
                </h1>
                <div className="mt-5">
                  <h1 className="text-[18px] sm:text-[20px] md:text-[24px] font-NeueMedium text-[#FF7B00]">
                    For admins
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[14px] sm:text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Admin Registration/Login</li>
                    <li>Account security set up</li>
                    <li>Admin Dashboard</li>
                    <li>Customer Account Management</li>
                    <li>Employee profile management</li>
                    <li>Financial Product Management</li>
                    <li>Transaction Auditing</li>
                    <li>Analytics and Reporting</li>
                    <li>System Customization</li>
                    <li>Communication and support system</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[100px]">
        <div className="lg:px-[3rem] md:px-[2rem] px-[1rem] mx-auto max-w-[87.5rem]">
          <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            Integrations
          </h1>
          <div>
            <div className="flex flex-wrap gap-4 sm:gap-6 w-full">
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaUser className="text-black text-[18px]" />
                </div>
                KYC Verification
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <BsTelephoneFill className="text-black text-[18px]" />
                </div>
                Mobile Network Operators (MNOs)
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosCard className="text-black text-[18px]" />
                </div>
                Payment gateways
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaMoneyBill className="text-black text-[18px]" />
                </div>
                Bill Payment Platform
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaEnvelope className="text-black text-[18px]" />
                </div>
                Messaging and Notification
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaMoneyCheck className="text-black text-[18px]" />
                </div>
                Loan Origination Platforms
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <GiWallet className="text-black text-[18px]" />
                </div>
                Savings Investment Platforms
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[80px] relative md:py-[100px]">
        <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-black to-[#45C4B0] opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="px-4 relative z-20 md:px-[3rem] lg:px-[5rem] max-w-[87.5rem] mx-auto">
          <h1 className="text-white text-2xl md:text-4xl font-NeueMedium mb-8 text-center md:text-left">
            Ultimate results from the partnership
          </h1>
          <div className="flex flex-col md:flex-row w-full items-center">
            <div className="w-full md:w-[50%] mb-8 md:mb-0">
              <img
                src={Ars}
                className="transform scale-x-[-1] filter grayscale mx-auto"
                alt="astronaut"
              />
            </div>
            <div className="flex flex-col w-full md:w-[50%]">
              {[
                "Expanded customer reach and accessibility",
                "Enhanced customer satisfaction through 24/7 banking services",
                "Improved Customer Experience",
                "Streamlined operations and reduced overhead costs",
                "Data-driven Insights",
                "Increased Loan service revenue",
              ].map((text, index) => (
                <div
                  key={index}
                  className="border-b border-[#FFFFFF29] py-3 flex items-center gap-5"
                >
                  <div className="h-[30px] w-[30px] rounded-full bg-[#45C4B0] flex-none flex items-center justify-center">
                    <IoMdCheckmark className="text-[18px]" />
                  </div>
                  <p className="text-white text-base md:text-lg font-NeueBold">
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="md:py-[100px] py-[80px]">
        <div className="lg:px-[5rem] md:px-[3rem] px-4 max-w-[87.5rem] mx-auto">
          <h1 className="text-white pb-8 md:text-4xl text-2xl font-NeueMedium">
            Technology stack
          </h1>

          <div className="flex flex-col md:flex-row gap-6 justify-between">
            {/* Mobile App Card */}
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={App} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Mobile app
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>React Native</li>
                    <li>Figma</li>
                    <li>Balsamiq</li>
                    <li>React Navigation</li>
                    <li>React Testing Library</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Frontend Card */}
            <div className="group hover:bg-[#FF7B00] rounded-[24px] pt-6 px-6 pb-9 bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Reactlogo} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Frontend
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>React.js</li>
                    <li>CSS</li>
                    <li>Tailwind CSS</li>
                    <li>Redux</li>
                    <li>react-intl</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Backend Card */}
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#C7FFED] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Db} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Backend
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Spring Boot</li>
                    <li>Java</li>
                    <li>PostgreSQL</li>
                    <li>JSON Web Tokens (JWT)</li>
                    <li>OAuth 2.0</li>
                    <li>RESTful APIs</li>
                    <li>Integration with third-party APIs</li>
                    <li>Encryption algorithms (AES, RSA)</li>
                    <li>HTTPS protocol</li>
                    <li>OWASP</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default DigitalBanking;
