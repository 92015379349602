import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/icons/eleven36logo.svg";
import { TiThMenuOutline } from "react-icons/ti";
import { GoDotFill } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
const Navbar = () => {
  const location = useLocation();
  const isAboutUsPage = location.pathname === "/about-us";
  const isExpertise = location.pathname === "/expertise";
  const isServices = location.pathname === "/services";
  const isPortfolio = location.pathname === "/portfolio";
  const [show, setShow] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight * 0.95) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);
  return (
    <div
      className={`fixed top-0 left-0 right-0 z-[9999] transition-colors duration-300 ${
        isScrolled ? "bg-black" : "bg-transparent"
      }`}
    >
      <div className="">
        <div className="flex items-center justify-between max-w-[87.5rem] mx-auto lg:px-[2rem] md:px-[1rem] px-[0.5rem] py-5">
          <Link to="/">
            <img src={Logo} className="md:w-[100%] w-[70%]" alt="logo" />
          </Link>
          <div className="text-white xmd:flex hidden items-center gap-4">
            <Link to="about-us">
              <h1
                className={`font-NeueMedium pb-2 ${
                  isAboutUsPage
                    ? "border-b-2 border-[#45C4B0] text-[#45C4B0]"
                    : ""
                }`}
              >
                About Us
              </h1>
            </Link>
            <Link to="expertise">
              <h1
                className={`font-NeueMedium pb-2 ${
                  isExpertise
                    ? "border-b-2 border-[#45C4B0] text-[#45C4B0]"
                    : ""
                }`}
              >
                Expertise
              </h1>
            </Link>
            <Link to="services">
              <h1
                className={`font-NeueMedium pb-2 ${
                  isServices ? "border-b-2 border-[#45C4B0] text-[#45C4B0]" : ""
                }`}
              >
                Services
              </h1>
            </Link>
            <Link to="portfolio">
              <h1
                className={`font-NeueMedium pb-2 ${
                  isPortfolio
                    ? "border-b-2 border-[#45C4B0] text-[#45C4B0]"
                    : ""
                }`}
              >
                Portfolio
              </h1>
            </Link>
          </div>
          <Link to="https://form.jotform.com/243076439302555" target="_blank">
            <button className="text-white items-center gap-x-2 border-[1.5px] xmd:flex hidden rounded-[60px] font-NeueMedium py-3 px-4 border-white">
              <span className="pulse rounded-full text-[#45C4B0]">
                <GoDotFill className="" size={16} />
              </span>
              Get in touch 
            </button>
          </Link>
          <button
            onClick={() => setShow(true)}
            className="text-white xmd:hidden block active:text-[#45C4B0] text-[30px]"
          >
            <TiThMenuOutline />
          </button>
          <AnimatePresence>
            {show && (
              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -100, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-black z-[100] text-center absolute top-0 left-0 xmd:hidden h-[100vh] w-full "
              >
                <div className="pt-[60px]">
                  <Link to="about-us" onClick={() => setShow(false)}>
                    <h1 className="font-NeueMedium py-7 text-white active:text-[#45C4B0]">
                      About Us
                    </h1>
                  </Link>
                  <Link to="expertise" onClick={() => setShow(false)}>
                    <h1 className="font-NeueMedium py-7 text-white active:text-[#45C4B0]">
                      Expertise
                    </h1>
                  </Link>
                  <Link to="services" onClick={() => setShow(false)}>
                    <h1 className="font-NeueMedium py-7 text-white active:text-[#45C4B0]">
                      Services
                    </h1>
                  </Link>
                  <Link to="portfolio" onClick={() => setShow(false)}>
                    <h1 className="font-NeueMedium py-7 text-white active:text-[#45C4B0]">
                      Portfolio
                    </h1>
                  </Link>

                  {/* <button className="text-white items-center gap-x-2 border-[1.5px] xmd:flex hidden rounded-[60px] font-NeueMedium py-3 px-4 border-white">
                    <span className="pulse rounded-full text-[#45C4B0]">
                      <GoDotFill className="" size={16} />
                    </span>
                    Get in touch
                  </button> */}
                </div>
                <AiOutlineClose
                  onClick={() => setShow(false)}
                  className=" text-white text-[25px] absolute top-10 right-10"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
