import { useState } from "react";
import Design from "../assets/images/design.mp4";
import Ideation from "../assets/images/ideation.mp4";
import Consultancy from "../assets/images/consultancy.mp4";
import Software from "../assets/images/soft.mp4";
import Null from "../assets/images/nullframe.svg";
import Frame1 from "../assets/images/frame1.svg";
import Frame2 from "../assets/images/frame2.svg";
import Frame3 from "../assets/images/frame3.svg";
import Frame4 from "../assets/images/frame4.svg";

const HowWeServeYou = () => {
  const [colorHover, setColorHover] = useState<null | string>("software");
  const [videoSrc, setVideoSrc] = useState(Software);

  const handleHover = (category: string | null) => {
    setColorHover(category);
    switch (category) {
      case "software":
        setVideoSrc(Software);
        break;
      case "design":
        setVideoSrc(Design);
        break;
      case "ideation":
        setVideoSrc(Ideation);
        break;
      case "consultancy":
        setVideoSrc(Consultancy);
        break;
      default:
        setVideoSrc(Software); // Default to software video if category is null
        break;
    }
  };

  const getGradientColor = () => {
    switch (colorHover) {
      case "software":
        return "from-black to-[#45C4B0]";
      case "design":
        return "from-black to-[#FFDA2C]";
      case "ideation":
        return "from-black to-[#FF7B00]";
      case "consultancy":
        return "from-black to-[#C7FFED]";
      default:
        return "";
    }
  };

  return (
    <div className="relative pt-[80px] md:pt-[100px] pb-[60px] h-auto md:h-[900px] overflow-hidden">
      <div
        className={`absolute inset-0 transition-all duration-[1300ms] w-full h-full from-black opacity-40 z-0 ${
          colorHover ? `bg-gradient-to-b ${getGradientColor()}` : ""
        }`}
      ></div>
      <div className="absolute bottom-0 right-0 minimd:block hidden">
        <img
          className={`${
            colorHover ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
          } transition-all duration-[1300ms] w-[100%]`}
          src={
            colorHover === "software"
              ? Frame1
              : colorHover === "design"
              ? Frame2
              : colorHover === "ideation"
              ? Frame3
              : colorHover === "consultancy"
              ? Frame4
              : Frame1 // Default to Frame1 for Software
          }
          alt="glow"
        />
      </div>

      <div className="relative z-20 text-white flex flex-col md:flex-row items-center px-[1rem] md:px-[2rem] lg:px-[3rem] max-w-[87.5rem] mx-auto justify-between mb-[100px] space-y-4 md:space-y-0">
        <h1 className="text-[28px] md:text-[44px] font-NeueMedium">
          How we serve you
        </h1>
        <button className="font-NeueMedium hover:border-[#45C4B0] hover:bg-[#45C4B0] hover:text-black py-[12px] px-[16px] rounded-[60px] border-white border-[1.5px]">
          Learn more
        </button>
      </div>

      <div className="relative flex flex-col md:flex-row px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem] justify-between space-y-6 md:space-y-0 space-x-0 md:space-x-10 z-20">
        {/* Video container */}
        <div className="h-[200px] md:h-[289px] transition-opacity duration-[500ms] w-full rounded-[20px] md:rounded-[25px] overflow-hidden">
          <video
            key={videoSrc}
            src={videoSrc}
            className="h-full w-full object-cover"
            autoPlay
            loop
            muted
            style={{ opacity: colorHover ? 1 : 0, transition: "opacity 1s" }}
          />
        </div>

        {/* Categories */}
        <div className="w-full">
          {[
            { label: "Software development", value: "software" },
            { label: "Design", value: "design" },
            { label: "Ideation", value: "ideation" },
            { label: "Consultancy", value: "consultancy" },
          ].map(({ label, value }) => (
            <h1
              key={value}
              onMouseEnter={() => handleHover(value)}
              onMouseLeave={() => handleHover("software")} // Show Software content when mouse leaves
              className={`text-[34px] md:text-[36px] lg:text-[50px] font-NeueBold cursor-pointer transition-transform duration-[1300ms] hover:translate-x-12 ${
                colorHover === value
                  ? `translate-x-12 text-${
                      value === "software"
                        ? "[#45C4B0]"
                        : value === "design"
                        ? "[#FFDA2C]"
                        : value === "ideation"
                        ? "[#FF7B00]"
                        : "[#C7FFED]"
                    }`
                  : "text-[#434343]"
              }`}
            >
              {label}
            </h1>
          ))}
        </div>
      </div>

      <div className="mt-6 pl-[1rem] md:pl-[2rem] lg:pl-[3rem] max-w-[87.5rem] mx-auto relative z-20">
        {[
          {
            label: "Software development",
            value: "software",
            description: "Web development, Mobile development & MVPs.",
          },
          {
            label: "Design",
            value: "design",
            description:
              "Identity Design - UI/UX Design & Product Design - Design System",
          },
          {
            label: "Ideation",
            value: "ideation",
            description:
              "R & D, Rapid Prototyping, Product Design, Validation Sprint & Go-To-Market Strategy.",
          },
          {
            label: "Consultancy",
            value: "consultancy",
            description:
              "Quality assurance, Product review/management, Growth strategy & Digital transformation.",
          },
        ].map(({ value, description }, index) => (
          <div
            key={index}
            className={`${
              colorHover === value ||
              (colorHover === null && value === "software")
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-8"
            } transition-all duration-[1300ms] w-full md:w-[50%]`}
          >
            {(colorHover === value ||
              (colorHover === null && value === "software")) && (
              <>
                <h1 className="text-white text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] font-NeueRegular">
                  {value === "software"
                    ? "Get your custom software product developed in record time using world-class engineering."
                    : value === "design"
                    ? "Experience sleek and intuitive digital designs that bring your vision to life."
                    : value === "ideation"
                    ? "Get Industry experts to explore, identify & validate your product ideas and plans."
                    : "Access strategic & data-driven insights to refine and navigate through any stage of your business dynamics."}
                </h1>
                <h1 className="text-[#A9A9A9] pt-3 text-[16px] sm:text-[20px] md:text-[24px] lg:text-[28px] font-NeueRegular">
                  {description}
                </h1>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowWeServeYou;
