import {
  FaArrowUp,
  FaEnvelope,
  FaHeart,
  FaSearchengin,
  FaStar,
  FaTruck,
} from "react-icons/fa";
import Phone1 from "../assets/images/market-hero.svg";
import Phone2 from "../assets/images/missionphone.svg";
import Ars from "../assets/images/Astronaut with space shuttle 1.gif";
import { IoIosCard, IoMdCheckmark, IoMdCube } from "react-icons/io";
import App from "../assets/images/app.svg";
import Db from "../assets/images/db.svg";
import Reactlogo from "../assets/images/react.svg";
import Phone from "../assets/images/solution-phone1.svg";
import { RiComputerFill, RiDiscountPercentFill } from "react-icons/ri";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { PiUserCircleFill } from "react-icons/pi";
import { BiSolidCartAlt, BiSolidMessageDetail } from "react-icons/bi";
import { IoCard } from "react-icons/io5";
import AndroidB from "../assets/images/AndroidB.svg";
import Logo from "../assets/images/Group (1).svg";
import HeroImg from "../assets/images/marthero.png";
// import HeroImg from "../assets/images/GroupPattern.svg";
import { useEffect, useState } from "react";
const DigitalMarketPlace = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="z-50">
      <div className="relative md:py-[100px] py-[80px] h-[95vh] overflow-hidden">
        <img
          src={HeroImg}
          className="absolute top-0 left-[23%] bottom-0 w-full h-full object-contain z-20"
          alt=""
        />
        {/* <img
          src={HeroImg}
          className="absolute top-0 left-[40%] bottom-0 w-full h-full object-cover z-20 container"
          alt=""
        /> */}
        <div className="absolute inset-0 w-full h-full opacity-40 radial-gradient-bg5 z-10"></div>

        <div className="max-w-[87.5rem] mx-auto px-4 md:px-[3rem] lg:px-[5rem]"></div>

        <div className="relative z-20 max-w-[87.5rem] mx-auto px-4 md:px-[3rem] lg:px-[5rem] h-full">
          <div className="w-full flex flex-col md:flex-row justify-between h-full">
            {/* Left Section */}
            <div className="w-full md:w-[55%] flex flex-col justify-center text-start md:text-left">
              <h1 className="text-white font-NeueMedium w-full text-[24px] md:text-4xl lg:text-5xl">
                Digital marketplace
              </h1>
              <h1 className="font-NeueMedium text-[20px] md:text-[22px] text-white mt-6 md:mt-8">
                Services:
              </h1>
              <h1 className="text-white font-NeueRegular w-full md:w-[80%] lg:w-[60%] text-sm md:text-base lg:text-lg mx-auto md:mx-0">
                Discovery phase, UI/UX design, Web & Mobile app development
              </h1>
            </div>

            {/* Right Section (Image) */}
            <div className="w-full md:w-[60%] h-full md:flex hidden items-center mt-8 md:mt-[70px]">
              <img
                src={Phone1}
                alt="Digital marketplace phone"
                className="w-[80%] md:w-full h-auto object-contain mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-white pt-[100px] mx-auto max-w-[87.5rem] px-4 md:px-[3rem] lg:px-[5rem]">
        <div className="flex">
          <div className="border-r border-[#FFFFFF52] inline-block pr-[50px]">
            <h1 className="font-NeueMedium text-[22px]">Industry:</h1>
            <h2 className="text-[15px] font-NeueRegular text-[#E7E7E7]">
              Ecommerce
            </h2>
          </div>
          <div className="pl-[50px] inline-block">
            <h1 className="font-NeueMedium text-[22px]">Team:</h1>
            <h2 className="text-[15px] font-NeueRegular text-[#E7E7E7]">
              7 Specialists
            </h2>
          </div>
        </div>
        <div className="inline-block pt-7">
          <h1 className="font-NeueMedium md:text-[22px] sm:text-[20px] text-[18px]">
            Project description:
          </h1>
          <h2 className="sm:text-[15px] text-[13px] font-NeueRegular sm:w-[60%] w-full text-[#E7E7E7]">
            A premium eCommerce platform built for online buying & selling,
            seamless payments, robust inventory management, customizable
            storefronts, and comprehensive analytics.
          </h2>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between sm:gap-[90px] gap-[45px] sm:text-start text-center pb-6 pt-[60px]">
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              6 months
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              For Full-feature Web & App
            </h2>
          </div>
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              3000+
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              Transactions in 1st week of Launch
            </h2>
          </div>
          <div>
            <h1 className="text-[#45C4B0] text-4xl sm:text-5xl md:text-[60px] font-NeueMedium pb-1">
              4.9
            </h1>
            <h2 className="text-white font-NeueMedium text-[14px] sm:text-[16px] md:text-[18px]">
              Combined store rating
            </h2>
          </div>
        </div>
      </div>

      <div className="py-[80px] sm:py-[100px] px-4 md:px-[3rem] lg:px-[5rem] mx-auto max-w-[87.5rem]">
        <h1 className="font-NeueMedium text-[28px] sm:text-[36px] md:text-[44px] text-white">
          About this project
        </h1>
        <div className="md:flex block items-start justify-between">
          <div className="w-[40%]"></div>
          <div className="md:w-[60%]">
            <div className="pb-7 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#45C4B0]">
                01
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The partner
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  The partner is an established e-commerce enterprise with a
                  diverse range of online marketplaces catering to various
                  consumer needs and preferences.
                </p>
              </div>
            </div>

            <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C]">
                02
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The big picture
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  The partner is an established e-commerce enterprise with a
                  diverse range of online marketplaces catering to various
                  consumer needs and preferences.
                </p>
              </div>
            </div>

            <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
              <h1 className="text-[40px] mt-[-18px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00]">
                03
              </h1>
              <div>
                <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                  The partner’s objectives
                </h1>
                <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  The partner aimed to empower customers with seamless access to
                  their purchase history, the ability to request orders, track
                  deliveries, and access product information round the clock.
                </p>
                <h1 className="text-[16px] pt-3 sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                  They sought to offer features for customers to renew
                  subscriptions, manage payment methods, and complete
                  transactions conveniently online. They sought to strategically
                  scale in a manner that gives room for sustainable growth
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[87.5rem] mx-auto px-4 md:px-[3rem] lg:px-[5rem] py-[100px]">
        <div>
          <h1 className="text-[44px] pb-6 font-NeueMedium text-white">
            Our process
          </h1>
          <div>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#45C4B0] flex items-center gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#45C4B0] text-[20px] font-NeueMedium rounded-full bg-black h-[40px] w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>1</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[20px] font-NeueMedium group-hover:hidden text-white transform transition-all duration-300">
                      Ideation
                    </h1>
                    <p className="text-white text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      The product and manager studied the project requirements,
                      developed a shared vision of the project, prepared a
                      backlog, setup tools, a project environment, and a
                      database.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#FFF500] flex items-center gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#FFF500] text-[20px] font-NeueMedium rounded-full bg-black h-[40px] w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>2</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[20px] whitespace-nowrap font-NeueMedium text-white group-hover:hidden transform transition-all duration-300">
                      Strategic analysis
                    </h1>
                    <p className="text-white text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      Our product and business analysts studied the project
                      requirements and provided software requirements and
                      specifications. Created high-level technical
                      specifications based on partner's requirements and
                      business objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4 mt-4">
              <div className="group bg-[#1C1C1C] hover:bg-[#FD8717] flex items-center gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#FD8717] text-[20px] font-NeueMedium rounded-full bg-black h-[40px] w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>3</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[20px] font-NeueMedium whitespace-nowrap group-hover:hidden text-white transform transition-all duration-300">
                      Technical development
                    </h1>
                    <p className="text-white text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      The Software engineers studied project requirements,
                      planned and described the architecture of the mobile and
                      web app.
                    </p>
                  </div>
                </div>
              </div>

              <div className="group bg-[#1C1C1C] hover:bg-[#C7FFED] flex items-center gap-5 p-6 rounded-[24px] w-full relative overflow-hidden transition-colors duration-300 h-[200px]">
                <div className="flex gap-5 items-center">
                  <div className="text-white group-hover:text-[#C7FFED] text-[20px] font-NeueMedium rounded-full bg-black h-[40px] w-[40px] inline-flex flex-none justify-center items-center transition-colors duration-300">
                    <h1>4</h1>
                  </div>
                  <div className="relative flex items-center">
                    <h1 className="text-[20px] font-NeueMedium group-hover:hidden text-white transform transition-all duration-300">
                      Design
                    </h1>
                    <p className="text-white text-[18px] font-NeueRegular opacity-0 group-hover:opacity-100 group-hover:text-black transition-all duration-300 ease-in-out transform translate-y-5 group-hover:translate-y-0">
                      Our Designer created an e-commerce mobile app design
                      concept based on the project requirements, brand book, and
                      partner's comment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#033A5499] py-[80px] md:py-[100px]">
        <div className="px-[1rem] md:px-[5rem] mx-auto flex flex-col md:flex-row items-center max-w-[87.5rem]">
          <div className="w-full md:w-[50%] mb-5 md:mb-0">
            <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
              Project mission
            </h1>
            <div className="pb-5">
              <p className="text-[#C7C7C7] md:text-[18px] text-[16px] font-NeueRegular">
                While the partner had an idea but no technical background, they
                were looking for a team to transform the business requirements
                into technical specifications and develop the agreed solution.
              </p>
            </div>
            <ul className="list-disc pl-5 text-[#C7C7C7]">
              <li className="md:text-[18px] text-[16px] pb-5">
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Validate the idea and prepare a detailed project roadmap;
                </span>
              </li>
              <li className="md:text-[18px] text-[16px] pb-5">
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Design interfaces for every user type: customers, store
                  owners, admins;
                </span>
              </li>
              <li className="md:text-[18px] text-[16px] pb-5">
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Develop a mobile e-commerce app for customers;
                </span>
              </li>
              <li className="md:text-[18px] text-[16px] pb-5">
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Develop a web e-commerce app for customers;
                </span>
              </li>
              <li className="md:text-[18px] text-[16px] pb-5">
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Develop admin panel to manage customer-shop owner transaction;
                </span>
              </li>
              <li className="md:text-[18px] text-[16px] pb-5">
                <span className="text-[#C7C7C7] font-NeueRegular">
                  Integrate the developed mobile e-commerce app with payment
                  system, email and SMS service.
                </span>
              </li>
            </ul>
          </div>
          <div className="w-full hidden md:w-[50%] md:flex justify-center">
            <img
              src={Phone2}
              alt="phone"
              className="w-full max-w-[300px] md:max-w-none"
            />
          </div>
        </div>
      </div>

      <div className="relative md:py-[100px] py-[80px] ">
        <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="max-w-[87.5rem] mx-auto lg:px-[5rem] md:px-[3rem] px-4">
          <h1 className="text-white relative z-20 text-center md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            The Solution
          </h1>
          <div className="flex justify-end items-center z-20 relative pb-[80px] pt-9">
            <img
              className="absolute left-[-80px] w-[70%] z-10"
              src={Phone}
              alt="phone"
            />
            <div className="bg-[#1C1C1C] w-[55%] rounded-[20px] p-[25px] ml-[25%] z-20 relative">
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                We developed a user-friendly mobile application for customers
                and an intuitive web application for merchants, complemented by
                a robust admin panel.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                Each part seamlessly interacts with each other, ensuring smooth
                operation and enhanced user experience.
              </h1>
              <h1 className="md:text-[18px] text-[16px] text-[#DADADA] pb-3 font-NeueRegular">
                The developed database securely stores information and
                synchronizes data with the website and partner data storage
                systems, ensuring reliability and consistency across all
                platforms.
              </h1>
            </div>
          </div>
          <hr className="text-[#FFFFFF29] w-full" />
        </div>

        <div className="relative z-20 max-w-[87.5rem] pt-[50px] mx-auto px-[5rem]">
          <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            Strategic advantages
          </h1>
          <div className="flex justify-between gap-4">
            <div>
              <h1 className="text-[#45C4B0] pb-3 text-[24px] font-NeueBold">
                Scalable
              </h1>
              <h1 className="text-[18px] text-[#C7C7C7] pb-3 font-NeueRegular">
                To ensure scalability of the e-commerce app, we implemented
                integrations that support adding an unlimited number of
                products, categories, and sellers without compromising
                performance.
              </h1>
              <h1 className="text-[18px] text-[#C7C7C7] font-NeueRegular">
                This ensures that the platform can grow seamlessly to
                accommodate the expanding needs of both customers and vendors,
                while maintaining optimal speed and efficiency.
              </h1>
            </div>
            <div>
              <h1 className="text-[#45C4B0] pb-3 text-[24px] font-NeueBold">
                Compliance and Regulation Adherence:
              </h1>
              <h1 className="text-[18px] text-[#C7C7C7] pb-3 font-NeueRegular">
                To elevate the user experience, the solution employs advanced
                algorithms for personalized product recommendations and
                customized shopping journeys.
              </h1>
              <h1 className="text-[18px] text-[#C7C7C7] font-NeueRegular">
                We utilized customer data to tailor suggestions, promotions, and
                content, ensuring that each user feels uniquely understood and
                catered to.
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[100px]">
        <div className="px-[5rem] mx-auto max-w-[87.5rem]">
          <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            Platform essential
          </h1>
          <div>
            <div className="flex flex-wrap gap-4 sm:gap-6 w-full">
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaStar className="text-black text-[18px]" />
                </div>
                User ratings & reviews
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoMdCube className="text-black text-[18px]" />
                </div>
                Product catalogue
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaSearchengin className="text-black text-[18px]" />
                </div>
                Search & filter
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <RiDiscountPercentFill className="text-black text-[18px]" />
                </div>
                Promotions & discounts
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaTruck className="text-black text-[18px]" />
                </div>
                Order tracking
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <AiFillSafetyCertificate className="text-black text-[18px]" />
                </div>
                Secure authentication
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaHeart className="text-black text-[18px]" />
                </div>
                Wishlist
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <PiUserCircleFill className="text-black text-[18px]" />
                </div>
                Personalized reccomendation
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <BiSolidCartAlt className="text-black text-[18px]" />
                </div>
                Shopping cart
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoCard className="text-black text-[18px]" />
                </div>
                Multiple payment options
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative py-[50px] md:py-[100px]">
        <div className="absolute inset-0 w-full h-full radial-gradient-bg2 opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="mx-auto relative z-20 max-w-[87.5rem] px-4 md:px-[5rem]">
          <h1 className="text-white text-[24px] md:text-[40px] pb-5 font-NeueMedium text-left">
            User adaptations
          </h1>
          <div>
            <div className="pb-5 border-b border-[#FFFFFF29] space-y-10 md:space-y-0 md:flex md:justify-between">
              <div className="flex space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#45C4B0]">
                  01
                </h1>
                <div className="mt-5">
                  <h1 className="text-[20px] sm:text-[24px] font-NeueMedium text-[#45C4B0]">
                    Customers/Buyers
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Account Registration/Login</li>
                    <li>Product Search and Filtering</li>
                    <li>Product Listings</li>
                    <li>Shopping Cart</li>
                    <li>Multiple Payment Options</li>
                    <li>Order Tracking</li>
                    <li>Wishlist</li>
                    <li>Customer Support</li>
                    <li>Personalised Recommendations</li>
                  </ul>
                </div>
              </div>
              <div className="flex space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C]">
                  02
                </h1>
                <div className="mt-5">
                  <h1 className="text-[20px] sm:text-[24px] font-NeueMedium text-[#FFDA2C]">
                    Vendors/sellers
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Registration/Login</li>
                    <li>Product Management</li>
                    <li>Order Management</li>
                    <li>Inventory Management</li>
                    <li>Customer Communication</li>
                    <li>Sales Analytics</li>
                    <li>Promotions & Discounts</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-10 relative md:mt-0">
              <div className="absolute left-0 bottom-0">
                <img src={Logo} className="w-[60%]" alt="logo" />
              </div>
              <div className="flex space-x-6 items-start">
                <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00]">
                  03
                </h1>
                <div className="mt-5">
                  <h1 className="text-[20px] sm:text-[24px] font-NeueMedium text-[#FF7B00]">
                    Admin
                  </h1>
                  <ul className="list-disc text-[#D3D3D3] text-[16px] md:text-[18px] pl-5 font-NeueRegular">
                    <li>Admin Dashboard</li>
                    <li>User Management</li>
                    <li>Content Management</li>
                    <li>Order Management</li>
                    <li>Analytics and Reporting</li>
                    <li>Shipping Management</li>
                    <li>Platform Administration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[100px]">
        <div className="px-[5rem] mx-auto max-w-[87.5rem]">
          <h1 className="text-white md:text-[40px] pb-5 text-[24px] font-NeueMedium">
            Integrations
          </h1>
          <div>
            <div className="flex flex-wrap gap-4 sm:gap-6 w-full">
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaEnvelope className="text-black text-[18px]" />
                </div>
                Email service
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <RiComputerFill className="text-black text-[18px]" />
                </div>
                Website
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <IoIosCard className="text-black text-[18px]" />
                </div>
                Payment system
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <BiSolidMessageDetail className="text-black text-[18px]" />
                </div>
                SMS service
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <FaTruck className="text-black text-[18px]" />
                </div>
                Shipping
              </div>
              <div className="py-[12px] cursor-pointer hover:text-black hover:bg-[#45C4B0] inline-flex items-center gap-2 px-[16px] border-[1.5px] hover:border-[#45C4B0] border-white rounded-[60px] text-white font-NeueMedium">
                <div className="bg-white p-1 rounded-full">
                  <AiFillSafetyCertificate className="text-black text-[18px]" />
                </div>
                Secure authentication
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[80px] relative md:py-[100px]">
        <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-black to-[#45C4B0] opacity-40 z-0"></div>
        <div className="absolute inset-0 w-full h-full bg-black opacity-40 z-10"></div>
        <div className="px-4 relative z-20 md:px-[3rem] lg:px-[5rem] max-w-[87.5rem] mx-auto">
          <h1 className="text-white text-2xl md:text-4xl font-NeueMedium mb-8 text-center md:text-left">
            Ultimate results from the partnership
          </h1>
          <div className="flex flex-col md:flex-row w-full items-center">
            <div className="w-full md:w-[50%] mb-8 md:mb-0">
              <img
                src={Ars}
                className="transform scale-x-[-1] filter grayscale mx-auto"
                alt="astronaut"
              />
            </div>
            <div className="flex flex-col w-full md:w-[50%]">
              {[
                "Increased reach",
                "24/7 Availability",
                "Improved Customer Experience",
                "Enhanced brand visibility",
                "Data-driven Insights",
                "Direct Marketing Channel",
                "Increased Sales and Revenue ",
              ].map((text, index) => (
                <div
                  key={index}
                  className="border-b border-[#FFFFFF29] py-3 flex items-center gap-5"
                >
                  <div className="h-[30px] w-[30px] rounded-full bg-[#45C4B0] flex items-center justify-center">
                    <IoMdCheckmark className="text-[18px]" />
                  </div>
                  <p className="text-white text-base md:text-lg font-NeueBold">
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="md:py-[100px] py-[80px]">
        <div className="lg:px-[5rem] md:px-[3rem] px-4 max-w-[87.5rem] mx-auto">
          <h1 className="text-white pb-8 md:text-4xl text-2xl font-NeueMedium">
            Technology stack
          </h1>

          <div className="flex flex-col md:flex-row gap-6 justify-between">
            {/* Mobile App Card */}
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={App} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    IOS
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Swift</li>
                    <li>CoreData</li>
                    <li>SwiftGen</li>
                    <li>Alamofire</li>
                    <li>PromiseKit</li>
                    <li>Yoomoney iOS SDK</li>
                    <li>Google Places SDK</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#45C4B0] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={AndroidB} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Android
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Kotlin</li>
                    <li>Coroutines</li>
                    <li>Retrofit</li>
                    <li>Room</li>
                    <li>GLIDE</li>
                    <li>Koin</li>
                    <li>Google Places SDK</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Frontend Card */}
            <div className="group hover:bg-[#FF7B00] rounded-[24px] pt-6 px-6 pb-9 bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Reactlogo} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Frontend
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>React</li>
                    <li>ReactDOM</li>
                    <li>React Redux</li>
                    <li>React Saga</li>
                    <li>Styled-components</li>
                    <li>Axios</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Backend Card */}
            <div className="rounded-[24px] pt-6 px-6 pb-9 group hover:bg-[#C7FFED] bg-[#1C1C1C] flex-1">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <img src={Db} className="mb-4" alt="logo" />
                  <h1 className="text-[#45C4B0] group-hover:text-black text-[24px] font-NeueBold">
                    Backend
                  </h1>
                </div>
                <div>
                  <ul className="list-disc text-[#D3D3D3] group-hover:text-black text-[18px] pl-5 font-NeueRegular">
                    <li>Spring Boot</li>
                    <li>Spring Core</li>
                    <li>Spring MVC</li>
                    <li>Spring Security</li>
                    <li>Spring Data JPA</li>
                    <li>Hibernate</li>
                    <li>Flyway</li>
                    <li>Apache Tomcat</li>
                    <li>REST API</li>
                    <li>Maven</li>
                    <li>Swagger</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 z-[99999999] bg-[#45C4B0] text-white rounded-full shadow-lg hover:bg-[#3ba494] transition-all"
        >
          <FaArrowUp size={24} />
        </button>
      )}
    </div>
  );
};

export default DigitalMarketPlace;
