import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Footer from "./components/Footer";
import ContactUsForm from "./components/ContactUsForm";
import AboutUs from "./pages/AboutUs";
import Expertise from "./pages/Expertise";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import DigitalBanking from "./pages/DigitalBanking";
import ElectronicMedical from "./pages/ElectronicMedical";
import DigitalMarketPlace from "./pages/DigitalMarketPlace";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="bg-black relative h-full w-full">
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/expertise" element={<Expertise />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/digital-banking-solution"
              element={<DigitalBanking />}
            />
            <Route
              path="/electronic-medical-records"
              element={<ElectronicMedical />}
            />
            <Route
              path="/digital-marketplace"
              element={<DigitalMarketPlace />}
            />
          </Routes>
          <ContactUsForm />
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
