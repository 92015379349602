import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const WhyEleven36 = () => {
  return (
    <div className="py-[80px] sm:py-[100px] px-[1rem] md:px-[2rem] lg:px-[3rem] mx-auto max-w-[87.5rem]">
      <h1 className="font-NeueMedium text-[28px] sm:text-[36px] md:text-[44px] text-white">
        Why Eleven36?
      </h1>
      <div className="md:flex block items-start justify-between">
        <div className="md:w-[40%]">
          {/* <button className="py-[12px] flex items-center gap-2 px-[16px] border-[1.5px] border-white rounded-[60px] text-white font-NeueMedium mt-8">
            Book a free consultation{" "}
            <FaArrowCircleRight className="text-white text-[16px] sm:text-[18px]" />
          </button> */}
          <Link className="btn-6 group font-NeueMedium" to="https://form.jotform.com/243076439302555" target="_blank">
            Book a free consultation{" "}
            <FaArrowCircleRight className="text-white text-[18px] icon group-hover:text-black" />
            <span></span>
          </Link>
        </div>
        <div className="md:w-[60%] md:pt-0 pt-7">
          <div className="pb-7 flex items-start space-x-6">
            <h1 className="text-[40px] sm:text-[60px] mt-[-18px] md:text-[80px] font-NeueBold text-[#45C4B0]">
              01
            </h1>
            <div>
              <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                Customized to you
              </h1>
              <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                From initial consultation to final deployment, our team works
                closely with you to ensure that every aspect of the software
                aligns perfectly with your goals and challenges.
              </p>
            </div>
          </div>

          <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
            <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FFDA2C] mt-[-18px]">
              02
            </h1>
            <div>
              <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                Launch in record time
              </h1>
              <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                Our agile methodologies, combined with a skilled team, ensure
                that your project is delivered on time without compromising
                quality.
              </p>
            </div>
          </div>

          <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
            <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#FF7B00] mt-[-18px]">
              03
            </h1>
            <div>
              <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                Safe, secure & compliant
              </h1>
              <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                Our software solutions are built with robust security protocols
                and adhere to the latest compliance standards.
              </p>
            </div>
          </div>

          <div className="py-7 border-t border-white border-opacity-35 flex items-start space-x-6">
            <h1 className="text-[40px] sm:text-[60px] md:text-[80px] font-NeueBold text-[#C7FFED] mt-[-18px]">
              04
            </h1>
            <div>
              <h1 className="text-[20px] sm:text-[24px] md:text-[28px] font-NeueMedium text-white">
                All you need in one place
              </h1>
              <p className="text-[16px] sm:text-[18px] md:text-[22px] text-[#A9A9A9] font-NeueRegular">
                From initial development to deployment and ongoing support, our
                integrated services ensure a seamless and efficient process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyEleven36;
